import { Component, Input } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { DeleteFavoriteMeal, GetFavoriteMeals, SetSelectedFave } from '../../../../../app/store/actions/user.action';
import { FavoriteOrderModalComponent } from '../favorite-order-modal/favorite-order-modal.component';
import {
  CreateFromFaves,
  TransferBasket,
  EmptyLimits,
  AddToCategoryLimit,
  AddReorderToLimit,
  CreateBasket,
  SetHandoffMode
} from '../../../../../app/store/actions/basket.action';
import {
  GetRestaurants,
  GetMenu,
  GetRestaurantCapacity,
  GetCalendars
} from '../../../../../app/store/actions/restaurant.action';
import { FavoritesOrderPayloadModel } from '../favorite-order-name/favorites-order.model';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Limiters, Limiter } from 'src/assets/chepri-modules/src/models/Limiter';
import { RestaurantStateModel } from '@app/store/state/restaurant.state';
import { Observable } from 'rxjs';
import { Basket } from '../../models/olo.basket';
import { AnalyticsService } from '@app/providers/analytics.service';
import { GlobalStateModel } from '@app/store/state.model';

@Component({
  selector: 'lib-favorite-orders',
  templateUrl: './favorite-orders.component.html',
  styles: []
})
export class FavoriteOrdersComponent {
  @Input() buttons = true;
  @Input() basketCreated = true;
  @Input() includesBafala = true;
  @Input() orderText = 'order';
  @Input() dropdownChildren = ['indivdual', 'group', 'catering'];
  @Input() hasFavoriteOrders: boolean;
  @Select() user$: any;
  @Select() restaurant$: Observable<RestaurantStateModel>;

  closeResult: string;

  @Input() favoriteOrders: any[];

  constructor(
    private modalService: NgbModal,
    protected store: Store,
    private toast: ToastrService,
    private router: Router,
    private analytics: AnalyticsService
  ) {
    this.store.dispatch(new GetFavoriteMeals());
  }

  clickEvent(event: any) {}

  handleFavoriteOrderSubmit(favoriteOrderModal: any) {
    this.analytics.logFavoriteOrderClick();
    const selectedFave = this.store.selectSnapshot(gsState => gsState.user.selectedFave);
    const currBasket: Basket = this.store.selectSnapshot(gsState => gsState.basket.basket);
    const basket = this.store.selectSnapshot(gsState => gsState.basket);
    const authToken = this.store.selectSnapshot(gsState => gsState.user.info.authtoken);
    const menu = this.store.selectSnapshot(gsState => gsState.restaurant.menu.categories);
    let limiters = new Limiters(basket.capacity._items);
    const faveMetadata: any = [];
    const quantity = 1;
    let canadd = true;
    const payload: FavoritesOrderPayloadModel = new FavoritesOrderPayloadModel(selectedFave.id, authToken);
    // check if favorite order is under max
    const faveItems = selectedFave.products;
    faveItems.forEach((items: any) => {
      const productName = items.name;
      menu.forEach((cat: any) => {
        cat.products.forEach((products: any) => {
          if (products.name === productName) {
            if (products.metadata) {
              const metadata = products.metadata;
              // tslint:disable-next-line: radix
              const categoryId = Number(metadata[metadata.map((el: any) => el.key).indexOf('category')].value);
              // tslint:disable-next-line: radix
              const categoryMax = Number(metadata[metadata.map((el: any) => el.key).indexOf('category_max')].value);
              for (let i = 0; i < items.quantity; i++) {
                if (faveMetadata.find((category: any) => category.categoryId === categoryId)) {
                  faveMetadata
                    .find((category: any) => category.categoryId === categoryId)
                    .products.push(items.productid);
                } else {
                  const data = {
                    products: [items.productid],
                    categoryId: categoryId,
                    max: categoryMax
                  };
                  faveMetadata.push(data);
                }
              }
            }
          }
        });
      });
    });

    faveMetadata.forEach((category: any) => {
      if (category.products.length > category.max) {
        canadd = false;
      }
    });

    if (canadd) {
      this.store.dispatch(new CreateFromFaves(payload)).subscribe(
        res => {
          if (currBasket && currBasket.vendorid) {
            this.store.dispatch(new SetHandoffMode(currBasket.deliverymode)).subscribe(_ => {
              if (selectedFave.vendorid !== currBasket.vendorid) {
                this.store.dispatch(new TransferBasket(Number(currBasket.vendorid))).subscribe(
                  (res: any) => {
                    this.store.dispatch(new GetMenu(currBasket.vendorid.toString())).subscribe(res => {
                      this.router.navigateByUrl('/order-details');
                      this.toast.success('Favorite Order Added to Basket');
                      this.store.dispatch(new EmptyLimits());
                      this.store.dispatch(new AddReorderToLimit(faveMetadata));
                    });
                  },
                  err => {
                    this.toast.warning('This order can not be placed at your selected location');
                    this.store.dispatch(new CreateBasket(currBasket.vendorid));
                  }
                );
              } else {
                this.router.navigateByUrl('/order-details');
                this.toast.success('Favorite Order Added to Basket');
                this.store.dispatch(new EmptyLimits());
                this.store.dispatch(new AddReorderToLimit(faveMetadata));
              }
            });
          } else {
            this.store.dispatch(new GetMenu(selectedFave.vendorid.toString())).subscribe(() => {
              const res = this.store.selectSnapshot((state: GlobalStateModel) => state.restaurant.restaurant);
              this.store.dispatch(new GetRestaurantCapacity(String(res.id))).subscribe(() => {
                this.router.navigateByUrl('/order-details');
                this.store.dispatch(new EmptyLimits());
                this.store.dispatch(new AddReorderToLimit(faveMetadata));
              });
            });
          }

          favoriteOrderModal.dismiss();
        },
        err => {
          this.toast.warning('The contents in you basket may have changed, please create the order again.');
        }
      );
    } else {
      this.toast.warning('This order goes over our capacity limits');
    }
  }

  deleteFavoriteOrder(event: any, faveid: any) {
    const payload = {
      id: faveid
    };
    this.store.dispatch(new DeleteFavoriteMeal(payload)).subscribe(res => {
      this.store.dispatch(new GetFavoriteMeals());
    });
  }

  favoriteOrderExpand(event: any, favoriteOrderPopup: any, order: any) {
    this.store.dispatch(new SetSelectedFave(order));
    const modal: NgbModalRef = this.modalService.open(favoriteOrderPopup, {
      ariaLabelledBy: 'favoriteOrderModal',
      centered: true
    });
    modal.result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  goToLocation() {
    this.router.navigateByUrl('/locations');
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
