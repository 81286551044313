import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';

@Component({
  selector: 'lib-kiosk-modifier-list',
  templateUrl: './modifier-list.component.html',
  styleUrls: ['../customer-kiosk.component.scss']
})
export class ModifierListComponent {
  @Input() listType = 'checkbox';
  @Input() values: string[];
  @Input() form: UntypedFormGroup;
  @Input() options: UntypedFormArray;
  @Input() text: string[];
  @Input() modifierID: number[];
  @Input() cost: number[];
  @Input() long: boolean;
  @Input() subModifiers: any[];
  @Input() selectedItems: any[];
  @Input() mode: string;
  @Input() showEnzoItem: boolean;
  @Input() enzoName: string;
  @Input() glutenFreeName: string;
  @Input() optionGroup: any;

  @Output() modifyEmit = new EventEmitter<any>();
  showitem = false;

  glutenFree: '';
  isSelected: boolean = false;

  constructor() {}

  modifyClicked(index: number) {
    this.modifyEmit.emit(index);
  }

  shiftSelect(event: any) {
    if (event.shiftKey) {
      this.isSelected = true;
    }
  }
}
