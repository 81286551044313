import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Product } from '../../models/olo.product';
import { CmsService } from '@app/providers/cms.service';
import { Store } from '@ngxs/store';
import { RestaurantState } from '@app/store/state/restaurant.state';
import { ProductService } from '../../../../../app/providers/product.service';
import { environment } from '../../../../../environments/environment';
import { ResponseRetrieveSavedProductsSavedProduct } from '@app/models/saved-products.olo';
import { DeleteSavedProduct } from '@app/store/actions/user.action';
import { GlobalStateModel } from '@app/store/state.model';

@Component({
  selector: 'lib-saved-product-card',
  templateUrl: './saved-product-card.component.html',
  styles: []
})
export class SavedProductCardComponent implements OnInit {
  @Input() product: ResponseRetrieveSavedProductsSavedProduct;
  @Input() text: string;
  @Input() defaultImageUrl: string;
  @Input() includesBafala: boolean;
  @Input() imagePath: string;
  @Output() addToBag = new EventEmitter<ResponseRetrieveSavedProductsSavedProduct>();
  @Input() isLoading: boolean;

  doneLoading = false;
  hasModifiers = false;

  constructor(private cms: CmsService, private store: Store, private productService: ProductService) {}

  ngOnInit() {
    this.doneLoading = true;
    // // If ingredients include Bafala
    // // TODO: Needs updated to check actual ingredients - might require getting modifiers/ingredients?
    // if (this.product.description && this.product.description.toLowerCase().includes('bufala')) {
    //   this.includesBafala = true;
    // }
    this.setImage();
    // this.productService.getProductOptions(this.product.id).subscribe(res => {
    //   if (res.optiongroups.length > 0) {
    //     this.hasModifiers = true;
    //   }
    //   this.doneLoading = true;
    // });
  }

  setImage() {
    const images = this.store.selectSnapshot((state: GlobalStateModel) => state.restaurant.images);
    const imagePath = images.data.find((obj: { name: string }) => obj.name === this.product.product.name);
    if (imagePath && imagePath.image) {
      this.imagePath = environment.cmsDomain.concat('assets/', imagePath.image.id);
    } else {
      this.imagePath = this.defaultImageUrl;
    }
  }

  emitAddToBag(product: ResponseRetrieveSavedProductsSavedProduct) {
    if (!this.doneLoading) {
      return;
    }
    this.addToBag.emit(product);
  }

  deleteEmit(product: ResponseRetrieveSavedProductsSavedProduct) {
    this.store.dispatch(new DeleteSavedProduct(product.id));
  }
}
