import { Injectable } from '@angular/core';
import { DirectusExpoProvider } from '@app/providers/expo/directus/directus-expo.provider';
import moment from 'moment';
import { OloApiService } from '@app/providers/expo/olo/olo-api.service';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { DirectusSchema, ExpoMetadata } from '@app/providers/expo/directus/directus-collections.interface';
import { AuthenticationClient, DirectusClient, RestClient, WebSocketClient } from '@directus/sdk';
import { interval } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderFireService {
  private client: DirectusClient<DirectusSchema> &
    RestClient<DirectusSchema> &
    AuthenticationClient<DirectusSchema> &
    WebSocketClient<DirectusSchema>;
  public expoOrders: ExpoMetadata[] = [];

  constructor(private expo: DirectusExpoProvider, private olo: OloApiService, private toastr: ToastrService) {
    this.client = this.expo.getClient();
    interval(5000).subscribe(async () => {
      await this.client.refresh();
      this.runCheck();
    });
  }

  runCheck() {
    // get orders for current location
    this.getExpoItems().subscribe((orders: ExpoMetadata[]) => {
      this.expoOrders = orders;
      // find all pre-fired and ready time before now + 10 minutes
      const now = moment();
      const tenMinutes = moment().add(10, 'minutes');
      const preFiredOrders = orders.filter((order: ExpoMetadata) => {
        const readyTime = moment(order.ready_time);
        return order.order_status === 'pre-fired' && readyTime.isSameOrBefore(tenMinutes, 'minute');
      });
      // fire all pre-fired orders
      preFiredOrders.forEach((order: ExpoMetadata) => {
        this.fireNow(order);
      });
    });
  }

  fireNow(order: ExpoMetadata) {
    this.olo
      .manuallyFireOrderByID(order.order_id, order.table_number, order.party_size, order.table_preference)
      .subscribe((res: any) => {
        if (res.success === true) {
          order.order_status = 'fired';
          order.fired_time = moment().format('YYYY-MM-DDTHH:mm:ss');
          this.expo.updateExpoOrder(order).subscribe(() => {});
        } else {
          this.toastr.warning(res.message);
        }
      });
  }

  getExpoItems() {
    const date = moment().format('YYYY-MM-DD');
    const location = this.expo.getCurrentLocation();
    return this.expo.getQueuedExpoOrders(date, location);
  }
}
