import { Component, OnInit, Input, Output, EventEmitter, ViewChild, NgZone } from '@angular/core';
import { LocationListComponent } from '../location-list/location-list.component';
import { Restaurant } from '../../models/olo.restaurant';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { Store, Select } from '@ngxs/store';
import { RestaurantState, RestaurantStateModel } from '@app/store/state/restaurant.state';
import { CheckDelivery, CleanCalendars, SetAddress, SetLocation } from '@app/store/actions/restaurant.action';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SetBanner } from '@app/store/actions/app.action';
import { Observable, Subscription } from 'rxjs';
import { AppState, AppStateModel } from '@app/store/state/app.state';
declare var google: any;

import { Geolocation, Position } from '@capacitor/geolocation';
import { Result } from '@mapbox/mapbox-gl-geocoder';
import { LocationListSearchService } from './location-search.service';
import { MobileService } from '../../../../../app/providers/mobile.service';
import { Locations } from '../../../../../app/models/directus.collections';
import { GlobalStateModel } from '@app/store/state.model';

interface OrderHeader {
  restaurantID: number;
  orderType: string;
}

@Component({
  selector: 'lib-locations',
  templateUrl: './locations.component.html',
  styles: []
})
export class LocationsComponent implements OnInit {
  @Input() locations: any;
  @Input() selectedLocation: number;
  @Input() cmsLocations: Locations[] = [];

  @Output() passOrderHeaderEvent = new EventEmitter<OrderHeader>();
  @Select() app$: Observable<AppStateModel>;
  @Select() restaurant$: Observable<RestaurantStateModel>;
  @ViewChild('searchBarComponent', { static: true }) searchBarComponent: SearchBarComponent;
  userLocation = this.store.selectSnapshot((state: GlobalStateModel) => state.restaurant.location);
  zoomLevel = 10;
  defaultZoom = this.mobile.isMobile ? 8 : 10;
  hasFoundLocation = false;
  geoLocationLoading = false;

  // @ViewChild('locationInfoModal') locationInfoModal: ModalDirective;
  @ViewChild('locationListComponent', { static: true }) locationListComponent: LocationListComponent;
  // @ViewChild('search', { static: false }) searchElementRef: any;
  // isBrowser: boolean;
  defaultLocation = { latitude: 44.959915, longitude: -93.189357 };
  currentLocation = '';
  findingLoc = false;
  searching = false;
  geocoder: any;
  autocomplete: any;
  distances: any;
  closeResult: string;
  locationsArray: Restaurant[];
  location: Restaurant;
  locationUpdated = false;
  subs: Subscription[] = [];
  constructor(
    private ngZone: NgZone,
    private store: Store,
    private modalService: NgbModal,
    private toast: ToastrService,
    private locationSearcher: LocationListSearchService,
    private mobile: MobileService
  ) {}

  ngOnInit() {
    this.geoLocationLoading = true;
    this.mobile.mobileCheck();
    this.defaultZoom = this.mobile.isMobile ? 8 : 10;
    this.setCurrentLocation();
    this.locationsArray = Object.values(this.locations);
    if (this.locationsArray && this.locationsArray[0]) {
      this.locationsArray = this.locationsArray.filter(item => item.zip);
    }

    this.subs.push(
      this.store
        .select((state: GlobalStateModel) => state.restaurant)
        .subscribe(restaurant => {
          if (restaurant.location && !!restaurant.location.latitude) {
            this.userLocation = restaurant.location;
            this.locationUpdated = true;
          }
        })
    );
  }

  public searcher(event: any) {}

  // DECOUPLING HELPERS

  // Collects order header emission and passes out application level
  collectOrderHeader(header: OrderHeader) {
    this.passOrderHeaderEvent.emit(header);
  }

  // Collect location emission and passes it to the location landing component
  collectLocation(location: Restaurant) {
    this.location = location;
    const body = {
      latitude: location.latitude,
      longitude: location.longitude
    };
    this.store.dispatch(new SetLocation(body));
    this.store.dispatch(new SetBanner('Find Your Location', false, false, location.name));
  }

  getDistance(lat1: any, lon1: any, lat2: any, lon2: any) {
    const R = 6371; // km
    const dLat = this.toRad(lat2 - lat1);
    const dLon = this.toRad(lon2 - lon1);
    lat1 = this.toRad(lat1);
    lat2 = this.toRad(lat2);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d;
  }

  // Converts numeric degrees to radians
  toRad(value: any) {
    return (value * Math.PI) / 180;
  }

  searchChange() {
    this.setLocations();
  }

  setLocations() {
    const restaurant = this.store.selectSnapshot((state: GlobalStateModel) => state.restaurant);
    const currentLocation = restaurant.location;
    if (currentLocation) {
      // this.locationsArray.map((loc: Restaurant, index: number) => {
      //   if (loc) {
      //     const dis = this.getDistance(
      //       loc.latitude,
      //       loc.longitude,
      //       currentLocation.latitude,
      //       currentLocation.longitude
      //     );
      //     const disInt = this.precisionRound(dis, 1);
      //     const distance = disInt < 9999 ? disInt : 0;
      //     console.log(loc.city, distance);
      //     sessionStorage.setItem(loc.city, String(distance));
      //   }
      // });
      this.locationsArray.sort((a: Restaurant, b: Restaurant) => {
        const a_dis = this.getDistance(a.latitude, a.longitude, currentLocation.latitude, currentLocation.longitude);
        const b_dis = this.getDistance(b.latitude, b.longitude, currentLocation.latitude, currentLocation.longitude);
        if (isFinite(a_dis - b_dis)) {
          return a_dis - b_dis;
        } else {
          return isFinite(a_dis) ? -1 : 1;
        }
      });
    }
    // this.getDistances();
  }

  getDistances() {
    this.distances = [];
    for (const location of this.locations) {
      const dis = Number(sessionStorage.getItem(location.city));
      const city = location.city;
      if (!this.distances.includes(city)) {
        this.distances.push({ city, dis });
      }
    }
  }

  precisionRound(number: any, precision: any) {
    if (number > 10) {
      return Math.round(number);
    } else {
      const factor = Math.pow(10, precision);
      return Math.round(number * factor) / factor;
    }
  }

  setCurrentLocation(isManualCheck?: boolean) {
    this.geoLocationLoading = true;
    Geolocation.getCurrentPosition({
      enableHighAccuracy: true,
      timeout: 5000
    })
      .then((coords: Position) => {
        this.setGeoLocation(coords.coords.latitude, coords.coords.longitude);
      })
      .catch(() => {
        if (isManualCheck) {
          this.toast.warning('Geolocation is not enabled');
        }
        this.geoLocationLoading = false;
      });
  }

  setGeoLocation(latitude: number, longitude: number) {
    const location = {
      latitude,
      longitude,
      streetAddress: '',
      city: '',
      zip: ''
    };
    this.store.dispatch(new SetLocation(location));
    this.geoLocationLoading = false;
    this.setLocations();
  }

  setAddress(result: Result) {
    const location = {
      latitude: result.geometry.coordinates[1],
      longitude: result.geometry.coordinates[0],
      streetaddress: '',
      city: '',
      zip: ''
    };
    if (result.id.toString().includes('place')) {
      location.city = result.text ? result.text : '';
    } else if (result.id.toString().includes('address')) {
      const street = result.text ? result.text : '';
      const streetnumber = result.address ? result.address : '';
      location.streetaddress = streetnumber + ' ' + street;
      location.city = result.context.find((comp: any) => comp.id.includes('place')).text;
      location.zip = result.context.find((comp: any) => comp.id.includes('postcode')).text;
    } else if (result.id.toString().includes('postcode')) {
      location.city = result.context.find((comp: any) => comp.id.includes('place')).text;
      location.zip = result.text ? result.text : '';
    }
    this.store.dispatch(new SetAddress(result.place_name));
    this.store.dispatch(new SetLocation(location));
    this.setLocations();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
