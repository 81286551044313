import moment from 'moment';
import { Injectable } from '@angular/core';

export interface DateTimeInterface {
  moment: moment.Moment;
  addMinutes(minutes: number): DateTime;
  compare(dateTime: DateTime): number;
  roundTimeToNearest(base: number): DateTime;
}

export class DateTime implements DateTimeInterface {
  moment: moment.Moment;

  /**
   * MM/DD/YYYY format, e.g. 01/01/2020
   */
  get shortDateString() {
    return this.moment.format('MM/DD/YYYY');
  }
  /**
   * MMM Do, YYYY format, e.g. Jan 1st, 2020
   */
  get longDateString() {
    return this.moment.format('MMM Do, YYYY');
  }
  /**
   * YYYYMMDD HH:mm format, e.g. 20200101 00:00
   */
  get oloDateTime() {
    return this.moment.format('YYYYMMDD HH:mm');
  }
  /**
   * YYYY-MM-DD HH:mm:ss format, e.g. 2020-01-01 00:00:00
   */
  get directusDateTime() {
    return this.moment.format('YYYY-MM-DD HH:mm:ss');
  }

  /**
   * YYYY-MM-DD
   */
  get directusDate(): string {
    return this.moment.format('YYYY-MM-DD');
  }

  /**
   * HH:mm:ss
   */
  get directusTime() {
    return this.moment.format('HH:mm:ss');
  }
  /**
   * Object similar to Olo model UpdateBasketTimeWantedPost, but without ismanualfire
   */
  get oloTimeWanted() {
    return {
      year: this.year,
      month: this.month,
      day: this.day,
      hour: this.hour,
      minute: this.min
    };
  }
  /**
   * hh:mm A format, e.g. 12:00AM
   */
  get time() {
    return this.moment.format('h:mm A');
  }
  /**
   * HH:mm format, e.g. 00:00
   */
  get time24() {
    return this.moment.format('HH:mm');
  }
  /**
   * YYYYY format, e.g. 2020
   */
  get year() {
    return this.moment.format('YYYYY');
  }
  /**
   * MM format, e.g. 01
   */
  get month() {
    return this.moment.format('MM');
  }
  /**
   * DD format, e.g. 01
   */
  get day() {
    return this.moment.format('DD');
  }
  /**
   * HH format, number of hours out of 24
   */
  get hour() {
    return this.moment.format('HH');
  }
  /**
   * mm format, number of minutes out of 60
   */
  get min() {
    return this.moment.format('mm');
  }

  /**
   * Creates a DateTime object from format 'YYYYMMDD HH:mm'
   * @param oloString time represented as 'YYYYMMDD HH:mm'
   */
  static fromOlo(oloString: string) {
    return new this(moment(oloString, 'YYYYMMDD HH:mm'));
  }

  /**
   * Creates a DateTime object from format 'YYYY-MM-DD HH:mm:ss'
   * @param directusString time represented as 'YYYY-MM-DD HH:mm:ss'
   */
  static fromDirectus(directusString: string) {
    return new this(moment(directusString, 'YYYY-MM-DDTHH:mm:ss'));
  }

  static fromDirectusDate(directusString: string): DateTime {
    return new this(moment(directusString, 'YYYY-MM-DD'));
  }

  /**
   * Creates a DateTime object from format 'HH:mm:ss'
   * @param directusString time represented as 'HH:mm:ss'
   */
  static fromDirectusTime(directusString: string) {
    return new this(moment(directusString, 'HH:mm:ss'));
  }

  /**
   *
   * @param date YYYYMMDD
   * @param minutes time of day represented in total minutes
   * @param time HH:MM time of day as 24 clock
   */
  constructor(m: moment.Moment) {
    this.moment = m;
  }

  /**
   * Adds specified number of minutes to a formatted time.  Meant for small amounts of time, i.e. less than an hour
   * @param minutes Number of minutes to add to the time
   *
   * @returns new formatted time, HH:mm format
   */
  addMinutes(minutes: number): DateTime {
    return new DateTime(this.moment.add(minutes, 'm'));
  }

  /**
   * Returns 1 if this is after the comparator time, -1 if this is before comparator, and 0 if equal
   * @param d object to compare to this
   */
  compare(d: DateTime): number {
    if (this.moment.isSame(d.moment)) {
      return 0;
    } else {
      return this.moment.isAfter(d.moment) ? 1 : -1;
    }
  }

  /**
   * Rounds the DateTime up to the next nearest interval of the specified base
   * @param base number which minutes will be rounded to (e.g. 15, 30)
   */
  roundTimeToNearest(base: number): DateTime {
    const m = this.moment.clone();
    // return new DateTime(m.ceil(base, 'minutes'));
    // Find remainder of minutes / index, add remainder - return new DateTime
    return new DateTime(m.add(base - (m.minute() % base), 'minutes'));
  }
}

@Injectable()
export class DateTimeService {
  compareDirectus(a: string, b: string): number {
    return DateTime.fromDirectus(a).compare(DateTime.fromDirectus(b));
  }
  compareDirectusToDirectusTime(directusDateTime: string, directusTime: string): number {
    return DateTime.fromDirectus(directusDateTime).compare(DateTime.fromDirectusTime(directusTime));
  }
}
