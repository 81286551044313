import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModeService } from '@app/providers/mode.service';
import { GetCalendars, GetMenu, GetRestaurantCapacity } from '@app/store/actions/restaurant.action';
import { Store } from '@ngxs/store';
import { DirectusCurrentOrder } from '@app/models/capacity.model';
import { RestaurantState } from '@app/store/state/restaurant.state';
import moment from 'moment';
import { CapacityService } from '@app/providers/capacity.service';
import { interval, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DirectusApiService } from '@app/providers/expo/directus/directus-api.service';
import { CustomerKioskService } from '@app/providers/customer-kiosk.service';
import { GlobalStateModel } from '@app/store/state.model';
import later from '@breejs/later';

@Component({
  selector: 'app-wait-time-display',
  templateUrl: './wait-time-display.component.html',
  styleUrls: ['./wait-time-display.component.scss']
})
export class WaitTimeDisplayComponent implements OnInit, OnDestroy {
  upcomingSlots: string[] = [];

  subscriptions: Subscription[] = [];

  private interval: any;

  constructor(
    private mode: ModeService,
    private store: Store,
    private capacity: CapacityService,
    private route: ActivatedRoute,
    private kioskService: CustomerKioskService
  ) {
    this.mode.setApplicationMode('wait-time');
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.params.subscribe(params => {
        if (params.locationID) {
          // this.setupCapacityData(params.locationID);
          const cron = later.parse.cron('* * * * *');
          this.interval = later.setInterval(() => this.setupCapacityData(params.locationID), cron);
        }
      })
    );
  }

  async init(locationID: string) {
    // connect
    await this.kioskService.client.connect();
    setTimeout(async () => {
      // subscribe
      const { subscription, unsubscribe } = await this.kioskService.client.subscribe('order_meta_data', {
        query: {
          fields: ['*'],
          filter: {
            location: { _eq: Number(locationID) }
          }
        }
      });
      // listen
      for await (const item of subscription) {
        this.setupCapacityData(locationID);
      }
    }, 300);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    if (this.interval) {
      this.interval.clear();
    }
  }

  setupCapacityData(kioskLocation: string) {
    this.store.dispatch(new GetMenu(String(kioskLocation))).subscribe(() => {
      const res = this.store.selectSnapshot((state: GlobalStateModel) => state);
      this.store.dispatch(new GetRestaurantCapacity(String(res.restaurant.restaurant.id))).subscribe(() => {
        this.store.dispatch(new GetCalendars(String(res.restaurant.restaurant.id), false)).subscribe(() => {
          const calres = this.store.selectSnapshot((state: GlobalStateModel) => state);
          this.setCapacityBuckets(calres, String(res.restaurant.restaurant.id));
        });
      });
    });
  }

  setCapacityBuckets(state: any, id: string) {
    // Get Locations orders
    this.subscriptions.push(
      this.capacity.getOrders(id, false).subscribe((orders: DirectusCurrentOrder[]) => {
        const timeSlots = this.capacity.getNextAvailableTimes(
          state.restaurant.capacity_settings,
          state.restaurant.calendar.calendar[0].ranges[0].end,
          orders,
          false
        );
        const restaurant = this.store.selectSnapshot((state: GlobalStateModel) => state.restaurant.restaurant);
        const waitTimes: string[] = [];
        timeSlots.forEach(slot => {
          if (slot) {
            const currentTime = moment();
            const keyTime = moment(slot.key, 'h:mm A').utcOffset(restaurant.utcoffset, true);
            const difference = moment(keyTime, 'HH:mm A').diff(moment(currentTime).startOf('minute'), 'minutes');
            waitTimes.push(difference.toString());
          }
        });
        if (waitTimes.length < 3) {
          this.upcomingSlots = Array.from({ ...waitTimes, length: 3 }, (v: string) => v ?? 'N/A');
        } else {
          this.upcomingSlots = waitTimes;
        }
      })
    );
  }
}
