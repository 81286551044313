import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { BasketStateModel, BasketState } from '@app/store/state/basket.state';
import { Router } from '@angular/router';
import { RestaurantStateModel, RestaurantState } from '@app/store/state/restaurant.state';
import { SetBanner } from '@app/store/actions/app.action';
import { RemoveOrderPlaceholder } from '@app/store/actions/basket.action';
import { FormatService } from 'src/assets/chepri-modules/src/providers/format.service';
import { UserStateModel } from '@app/store/state/user.state';
import { DateTime } from 'src/assets/chepri-modules/src/models/DateTime';
import { Basket } from '../../../assets/chepri-modules/src/models/olo.basket';
import { first, take } from 'rxjs/operators';
import { CurrencyPipe } from '@angular/common';
import { DirectusExpoProvider } from '@app/providers/expo/directus/directus-expo.provider';
import { EmailService } from '@app/providers/expo/email/email.service';
import { CleanGiftCard } from '@app/store/actions/basket.action';
import { ExpoMetadata } from '@app/providers/expo/directus/directus-collections.interface';
import { GlobalStateModel } from '@app/store/state.model';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit, OnDestroy {
  @Select() basket$: Observable<BasketStateModel>;
  @Select() restaurant$: Observable<RestaurantStateModel>;
  @Select() user$: Observable<UserStateModel>;

  @Input() options: { value: string; text: string };
  @Input() deliveryInstructions = 'Deliver to the top of the stairs at the back of the building.';

  pickupInstructions =
    'Please go directly to the pick-up counter (look for the hanging "Online Pickup" sign) and give your name to the expediter.';

  userLocation = { latitude: 39.981007, longitude: -83.0085312 };
  address = '';
  orderType = '';
  basket: any;
  instructionsInt: any;
  expoOrder: any;

  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private store: Store,
    private format: FormatService,
    private expo: DirectusExpoProvider,
    private email: EmailService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new RemoveOrderPlaceholder());
    this.store.dispatch(new SetBanner('ORDER CONFIRMATION', false, true, ''));
    this.basket = this.store.selectSnapshot((state: GlobalStateModel) => state.basket);
    this.orderType = this.basket.previousBasket.deliverymode;
    this.updatePickupText(this.basket.previousBasket.readytime);
    this.instructionsInt = setInterval(() => this.updatePickupText(this.basket.previousBasket.readytime), 10000); // Update every 10 seconds

    this.subscriptions.push(
      this.basket$.pipe(take(1)).subscribe(basket => {
        if (basket.previousBasket.deliverymode === 'dinein') {
          this.expo.getExpoOrderByOrderId(basket.previousBasket.id).subscribe((res: ExpoMetadata[]) => {
            this.expoOrder = res[0];
            localStorage.removeItem('emailAddress');
            this.email
              .postConfirmation({
                expoOrder: this.expoOrder,
                basket: this.basket,
                emailAddress: basket.billingInfo.emailaddress
              })
              .subscribe(() => {});
          });
        }
      })
    );
  }

  ngOnDestroy() {
    clearInterval(this.instructionsInt);
    this.store.dispatch(new CleanGiftCard());
  }

  continueToNextPage() {
    this.router.navigateByUrl('/menu');
  }

  getDirections() {
    const restaurant = this.store.selectSnapshot((state: GlobalStateModel) => state.restaurant.restaurant);
    let address = '';
    address += restaurant.streetaddress + ',+';
    address += restaurant.city + ',+';
    address += restaurant.state + '+';
    address += restaurant.zip;
    this.address = address;
    window.open('https://www.google.com/maps/place/' + this.address, '_blank', 'width=1000,height=800');
  }
  viewMenu() {
    this.router.navigateByUrl('/menu');
  }

  updatePickupText(readytime: any) {
    const readyTime2 = this.readyTimeToDate(readytime);
    const orderTime = this.getDayTime(readyTime2);
    const minutesUntilEntry = Math.ceil((readyTime2.getTime() - new Date().getTime()) / 60000) - 5;

    const minutePlural = minutesUntilEntry === 1 ? 'minute' : 'minutes';
    if (minutesUntilEntry > 0) {
      this.pickupInstructions =
        'Your order will be ready at ' +
        orderTime +
        '. Please enter the restaurant in ' +
        minutesUntilEntry +
        ' ' +
        minutePlural +
        '. Thanks!';
    } else {
      this.pickupInstructions =
        'Your order will be ready at ' +
        orderTime +
        '. Please enter the restaurant and give your name to the Host. Thanks!';
    }
  }

  getDayTime(time: Date) {
    let halfDay = 'AM';
    let thisHour = time.getHours();
    if (thisHour >= 12) {
      halfDay = 'PM';
      thisHour -= 12;
    }
    if (thisHour === 0) {
      thisHour = 12;
    }
    let thisMinutes = String(time.getMinutes());
    if (thisMinutes.length <= 1) {
      thisMinutes = '0' + thisMinutes;
    }
    const thisTime = thisHour + ':' + thisMinutes + ' ' + halfDay;

    return thisTime;
  }

  readyTimeToDate(time: string): Date {
    return new Date(
      Number(time.substring(0, 4)),
      Number(time.substring(4, 6)) - 1,
      Number(time.substring(6, 8)),
      Number(time.substring(9, 11)),
      Number(time.substring(12))
    );
  }

  getTime(oloTime: string): string {
    return DateTime.fromOlo(oloTime).time;
  }
}
