import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  SimpleChange
} from '@angular/core';
import { ProductService } from '../../providers/product.service';
import { OptionGroup } from '../../models/olo.optiongroup';
import { Product } from '../../models/olo.product';
import { BasketProductToAdd, BasketProduct } from '../../models/olo.basketproduct';
import { Store } from '@ngxs/store';
import { BasketState } from '@app/store/state/basket.state';
import { CustomizeItemService } from './customize-item.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TrancloudProviderService } from '../../../../../app/datacap-trancloud/services/trancloud-provider.service';
import { GlobalStateModel } from '@app/store/state.model';

@Component({
  selector: 'lib-customize-item',
  templateUrl: './customize-item.component.html',
  styles: []
})
export class CustomizeItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() product: Product;
  @Input() defaultImageUrl: string;
  @Input() displayImage = true;
  @Input() isEdit: boolean;
  @Input() basketProductId: number;
  @Input() isLoading: boolean;
  @Input() mode: string;
  @Input() showEnzoItem: boolean;
  @Input() enzoName: string;
  @Input() glutenFreeName: string;
  @Input() accessibilityMode: boolean;

  @Output() onAddToCart = new EventEmitter<any>();

  specialInstructionsForm: UntypedFormGroup;
  selectedArr: any = [];
  subs: any = [];
  selectedStr = '';
  basketProdQuant = 1;
  isPizza = false;
  selectedOptionGroup = 0;

  private _optionGroups: OptionGroup[];

  get optionGroups(): OptionGroup[] {
    return this._optionGroups;
  }

  @Input()
  set optionGroups(val: OptionGroup[]) {
    this._optionGroups = val;
  }

  constructor(
    private productService: ProductService,
    private store: Store,
    private customize: CustomizeItemService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    // Initialize selected array
    // By default, the optionid's for included and additional toppings need to be added
    // this.selectedArr = this.getDefaults(this.optionGroups);
    // this.productService.setSelected(this.selectedArr);
    this.subscribeToSelected();
    this.specialInstructionsForm = this.fb.group({
      specialInstructions: ['', [Validators.required]]
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentOptionGroups: SimpleChange = changes.optionGroups;
    if (currentOptionGroups && currentOptionGroups.currentValue) {
      const newOG = currentOptionGroups.currentValue;
      if (this.customize.isPizzaFormat(newOG)) {
        this.isPizza = true;
      } else {
        this.isPizza = false;
      }

      if (this.isEdit) {
        // Get Basket product
        const basketProduct: BasketProduct = this.store
          .selectSnapshot((state: GlobalStateModel) => state.basket.basket)
          .products.find((p: BasketProduct) => p.id == this.basketProductId);
        if (basketProduct?.specialinstructions) {
          this.specialInstructionsForm.get('specialInstructions').patchValue(basketProduct.specialinstructions);
        }
        this.selectedArr = this.getDefaults(currentOptionGroups.currentValue);
        this.selectedArr.push(...this.getSelectedFromBasketProduct(basketProduct));
        this.basketProdQuant = basketProduct.quantity;
      } else {
        this.selectedArr = this.getDefaults(currentOptionGroups.currentValue);
      }
      this.productService.setSelected(this.selectedArr);
    }
  }

  ngOnDestroy() {
    this.subs.forEach((sub: any) => sub.unsubscribe());
  }

  getDefaults(groups: OptionGroup[]): number[] {
    const temp: any = [];
    groups.forEach(optionGroup => {
      if (optionGroup.description === 'Customize') {
        if (optionGroup.options.find(op => op.name === 'Included' || op.name === 'Included Ingredients')) {
          temp.push(optionGroup.options.find(op => op.name === 'Included' || op.name === 'Included Ingredients').id);
        }
        if (optionGroup.options.find(op => op.name === 'Additional Toppings' || op.name === 'Additional Ingredients')) {
          temp.push(
            optionGroup.options.find(op => op.name === 'Additional Toppings' || op.name === 'Additional Ingredients').id
          );
        }
      } else if (optionGroup.description.toLowerCase().includes('size')) {
        temp.push(optionGroup.options[0].id);
        if (optionGroup.options[0].modifiers.some(m => m.description === 'Customize')) {
          const customize = optionGroup.options[0].modifiers.find(m => m.description === 'Customize');
          if (customize.options.some(o => o.name.toLowerCase().includes('included'))) {
            temp.push(customize.options.find(o => o.name.toLowerCase().includes('included')).id);
          }
          if (customize.options.some(o => o.name.toLowerCase().includes('additional'))) {
            temp.push(customize.options.find(o => o.name.toLowerCase().includes('additional')).id);
          }
        }
      }
    });
    return temp;
  }

  getSelectedFromBasketProduct(basketProduct: BasketProduct): number[] {
    const arr: number[] = [];
    basketProduct.choices.forEach(c => {
      if (!this.selectedArr.includes(c.optionid)) {
        arr.push(c.optionid);
      }
    });
    return arr;
  }

  subscribeToSelected() {
    this.subs.push(
      this.productService.selected.subscribe(() => {
        this.selectedArr = [...this.productService.getSelected()];
      })
    );
  }

  addToCart(event: any) {
    const specialInstructions = this.specialInstructionsForm.get('specialInstructions').value;
    if (specialInstructions && specialInstructions !== '') {
      event[0].specialinstructions = specialInstructions;
    }
    this.onAddToCart.emit(event);
  }

  inputChanged() {
    // this.errorMessage = '';
  }
}
