import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Angulartics2Module } from 'angulartics2';

import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { ShellModule } from './components/shell/shell.module';
import { LoginModule } from './components/login/login.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LocationsPageModule } from './components/locations/locations.module';
import { MenuPageModule } from './components/menu/menu.module';
import { SignInPageModule } from './components/sign-in/sign-in.module';
import { SignUpPageModule } from './components/sign-up/sign-up.module';
import { GuestInfoPageModule } from './components/guest-info/guest-info.module';
import { GiftCardPageModule } from './components/giftcard/giftcard.module';
import { AccountPageModule } from './components/account/account.module';
import { OrderDetailsPageModule } from './components/order-details/order-details.module';
import { CheckoutPageModule } from './components/checkout/checkout.module';
import { ConfirmPageModule } from './components/confirm/confirm.module';
import { ResetPasswordPageModule } from './components/reset-password/reset-password.module';
import { ProductModule } from './components/product/product.module';
import { ServicesModule } from './providers/services.module';
import { NgxsModule } from '@ngxs/store';
import { RestaurantState } from './store/state/restaurant.state';
import { ProductState } from './store/state/product.state';
import { AppState } from './store/state/app.state';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { BasketState } from './store/state/basket.state';
import { UserState } from './store/state/user.state';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '@env/environment';
import { ButtonModule } from '../assets/chepri-modules/src/lib/button/button.module';
import { SvgSpriteModule } from '../assets/chepri-modules/src/lib/svg-sprite/svg-sprite.module';
import { LoginModule as KioskExpoLoginModule } from './components/kiosk-expo/login/login.module';
import { StartOrderModule as KioskStartOrderModule } from './components/kiosk-expo/kiosk/start-order/start-order.module';
import { FiredModule as ExpoFiredModule } from './components/kiosk-expo/expo/fired/fired.module';
import { QueueModule as ExpoQueueModule } from './components/kiosk-expo/expo/queue/queue.module';
import { KioskMenuModule } from './components/kiosk-expo/kiosk/menu/menu.module';
import { KioskPastOrdersModule } from './components/kiosk-expo/kiosk/past-orders/past-orders.module';
import { KioskProductModule } from './components/kiosk-expo/kiosk/product/product.module';
import { DatacapTrancloudModule } from '@app/datacap-trancloud/datacap-trancloud.module';
import { GuestInfoModalModule } from './components/guest-info-modal/guest-info-modal.module';
import { KioskCheckoutPageModule } from './components/kiosk-expo/kiosk/checkout/checkout.module';
import { KioskConfirmationPageModule } from './components/kiosk-expo/kiosk/confirmation/confirmation.module';
import { ButtonFeedbackDirective } from '@app/shared/directives/button-feedback.directive';
import { AppUpdatingModalComponent } from './components/app-updating-modal/app-updating-modal.component';
import { HomeModule as CustomerHomeModule } from './components/customer-kiosk/home/home.module';
import { CustomerKioskMenuModule } from './components/customer-kiosk/menu/menu.module';
import { CustomerKioskCheckoutModule } from '@app/components/customer-kiosk/checkout/checkout.module';
import { CustomerKioskOrderSummaryModule } from '@app/components/customer-kiosk/order-summary/order-summary.module';
import { CustomerKioskConfirmationModule } from '@app/components/customer-kiosk/confirmation/confirmation.module';
import { CustomerKioskProductModule } from './components/customer-kiosk/product/product.module';
import { CustomerKioskLoginModule } from './components/customer-kiosk/login/login.module';
import { WaitTimeRoutingModule } from '@app/components/wait-time/wait-time-routing.module';
import { WaitTimeModule } from '@app/components/wait-time/wait-time.module';
import { DeleteAccountModalComponent } from './components/delete-account-modal/delete-account-modal.component';
import { AlcoholStatusModalComponent } from '@app/components/alcohol-status-modal/alcohol-status-modal.component';
import { DineInConfirmationModalComponent } from './components/dine-in-confirmation-modal/dine-in-confirmation-modal.component';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
// import { RefreshHandlerInterceptor } from '@app/providers/expo/directus/refresh-handler.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    AppUpdatingModalComponent,
    DeleteAccountModalComponent,
    AlcoholStatusModalComponent,
    DineInConfirmationModalComponent
  ],
  /*
    providers: [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: RefreshHandlerInterceptor,
        multi: true
      }
    ],
    */
  bootstrap: [AppComponent],
  imports: [
    NgxsModule.forRoot([RestaurantState, ProductState, AppState, BasketState, UserState], {
      developmentMode: !environment.production
    }),
    NgxsStoragePluginModule.forRoot({
      storage: 1,
      keys: ['product', 'restaurant', 'app', 'basket', 'user']
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      name: 'Punch Pizza - NGXS'
    }),
    NgbModule,
    BrowserModule,
    FormsModule,
    TranslateModule.forRoot(),
    NgbModule,
    CoreModule,
    SharedModule,
    ShellModule,
    // HomeModule,
    LocationsPageModule,
    ResetPasswordPageModule,
    MenuPageModule,
    SignInPageModule,
    SignUpPageModule,
    GuestInfoPageModule,
    GuestInfoModalModule,
    AccountPageModule,
    OrderDetailsPageModule,
    CheckoutPageModule,
    KioskCheckoutPageModule,
    KioskConfirmationPageModule,
    ConfirmPageModule,
    LoginModule,
    GiftCardPageModule,
    ProductModule,
    KioskExpoLoginModule,
    CustomerHomeModule,
    CustomerKioskMenuModule,
    CustomerKioskCheckoutModule,
    CustomerKioskOrderSummaryModule,
    CustomerKioskConfirmationModule,
    CustomerKioskProductModule,
    CustomerKioskLoginModule,
    KioskStartOrderModule,
    ExpoFiredModule,
    ExpoQueueModule,
    KioskMenuModule,
    KioskProductModule,
    KioskPastOrdersModule,
    ServicesModule,
    Angulartics2Module.forRoot(),
    WaitTimeModule,
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
    CommonModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    ButtonModule,
    SvgSpriteModule,
    // DatacapTrancloudModule.forRoot(environment.trancloudConfig)
    DatacapTrancloudModule,
    GoogleTagManagerModule.forRoot({
      id: 'GTM-KLDCB2MR'
    })
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class AppModule {}
