import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Logger } from '../logger.service';
import { CredentialsService } from './credentials.service';
import { Store } from '@ngxs/store';
import { GlobalStateModel } from '@app/store/state.model';

const log = new Logger('GuestGuard');

@Injectable({
  providedIn: 'root'
})
export class GuestGuard {
  constructor(private router: Router, private credentialsService: CredentialsService, private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.store.selectSnapshot((state: GlobalStateModel) => state.user.info) || this.credentialsService.isGuest()) {
      return true;
    }

    log.debug('Not a guest or authenticated, redirecting and adding redirect url...');
    this.router.navigate(['/sign-in'], { queryParams: { from: 'checkout', redirect: state.url }, replaceUrl: true });
    return false;
  }
}
