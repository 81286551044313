import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { BasketState, BasketStateModel } from '@app/store/state/basket.state';
import { UserState } from '@app/store/state/user.state';
import { Router } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import {
  ApplyCoupon,
  ApplyGiftCard,
  CheckBalance,
  CleanGiftCard,
  GetBillingSchemes,
  GetTimeSlots,
  RemoveCoupon,
  RemoveGiftCard,
  RemoveProductFromBasket,
  SetBillingInfo,
  SetBuckets,
  SetChange,
  SetTimeWanted,
  SetTipAmount,
  SubmitBasket,
  SubmitTrancloudBasket,
  ValidateBasket
} from '@app/store/actions/basket.action';
import { ToastrService } from 'ngx-toastr';
import { BillingInfo } from 'src/assets/chepri-modules/src/models/olo.billinginfo';
import { SetBanner } from '@app/store/actions/app.action';
import { CapacityService } from '@app/providers/capacity.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { BasketChoice, BasketProduct } from 'src/assets/chepri-modules/src/models/olo.basketproduct';
import { RestaurantState } from '@app/store/state/restaurant.state';
import { CmsService } from '@app/providers/cms.service';
import { GiftCardService } from '@app/providers/giftCard.service';
import { SplitPaymentService } from '@app/providers/splitpayment.service';
import { TrancloudProviderService } from '@app/datacap-trancloud/services/trancloud-provider.service';
import { DineInName } from 'src/assets/chepri-modules/src/models/dineinname';
import { SetDineInName, SetPartySize } from '@app/store/actions/user.action';
import { SwipeCardModalComponent } from '@app/components/swipe-card-modal/swipe-card-modal.component';
import { DirectusExpoProvider } from '@app/providers/expo/directus/directus-expo.provider';
import { DirectusApiService } from '@app/providers/expo/directus/directus-api.service';
import { TimeSlot } from '@app/models/capacity.model';
import { filter, take } from 'rxjs/operators';
import { GlobalStateModel } from '@app/store/state.model';
import { AlcoholStatus, Product } from '@lib/models/olo.product';
import { Category } from '@lib/models/olo.category';
import { AlcoholStatusModalComponent } from '@app/components/alcohol-status-modal/alcohol-status-modal.component';

declare var OrderNotifications: any; // Used by Flutter App; DO NOT REMOVE

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class KioskCheckoutComponent implements OnInit {
  @Select() basket$: Observable<BasketStateModel>;
  @Select((state: GlobalStateModel) => state.basket.timeSlots) timeSlots$: Observable<TimeSlot[]>;

  firstPaymentMethod: UntypedFormControl = new UntypedFormControl('creditcard');
  secondPaymentMethod: UntypedFormControl = new UntypedFormControl('secondcreditcard');
  maxTableSize = 8;
  selectedTableSize: number = null;
  tableSizeArray = Array;
  buttonAvailable = false;
  showTip = false;
  giftTotal = 0;
  needsSecondPayment = false;
  showBalance = false;
  balance = 0;
  giftCardBalance = 0;
  basketSubmit: BillingInfo = new BillingInfo();
  giftCard = false;
  cashPaymentTotal = 0;
  changeDue: number;
  allowSplitPayment = true;
  amExMask = '0000-000000-000009';
  regularMask = '0000-0000-0000-0000';
  currentCardMask = this.regularMask;
  currentCVVMask = '000';
  currentZIPMask = '00000';
  isLoading = false;
  couponCollection: any[] = [];
  fullComp = false;

  tableNumberForm = new UntypedFormGroup({
    tableNumberControl: new UntypedFormControl()
  });

  couponPromoForm = new UntypedFormGroup({
    couponCode: new UntypedFormControl()
  });

  userForm = new UntypedFormGroup({
    firstName: new UntypedFormControl(),
    lastName: new UntypedFormControl(),
    emailAddress: new UntypedFormControl()
  });

  firstGiftCardForm = new UntypedFormGroup({
    cardNumber: new UntypedFormControl(),
    pinNumber: new UntypedFormControl()
  });

  secondGiftCardForm = new UntypedFormGroup({
    cardNumber: new UntypedFormControl(),
    pinNumber: new UntypedFormControl()
  });

  firstCashPayment = new UntypedFormGroup({
    amount: new UntypedFormControl()
  });

  secondCashPayment = new UntypedFormGroup({
    amount: new UntypedFormControl()
  });

  creditCardPayment = new UntypedFormGroup({
    cardNumber: new UntypedFormControl(),
    expDate: new UntypedFormControl(),
    cvvCode: new UntypedFormControl(),
    zipCode: new UntypedFormControl()
  });

  timeForm = new UntypedFormGroup({
    time: new UntypedFormControl(null)
  });

  tipForm = new UntypedFormGroup({
    tip: new UntypedFormControl()
  });

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private store: Store,
    private toastr: ToastrService,
    private currencyPipe: CurrencyPipe,
    private capacityService: CapacityService,
    private modalService: NgbModal,
    private cms: CmsService,
    private gc: GiftCardService,
    private splitPay: SplitPaymentService,
    private tranCloud: TrancloudProviderService,
    private directusExpo: DirectusExpoProvider,
    private directusApi: DirectusApiService
  ) {
    this.store.dispatch(new ValidateBasket());
    this.store.dispatch(new SetBanner('BACK', true, true, ''));
    this.basketSubmit = { ...this.store.selectSnapshot(state => state.basket.billingInfo) };
  }

  ngOnInit() {
    this.alcoholModal();
    this.store.dispatch(new GetBillingSchemes());
    const rest = this.store.selectSnapshot((state: GlobalStateModel) => state.restaurant.restaurant);
    this.cms.getCMSLocations().subscribe((res: any) => {
      res.forEach((loc: any) => {
        if (loc.location_name === rest.name) {
          if (loc.max_table_number) {
            this.maxTableSize = Number(loc.max_table_number);
          }
        }
      });
    });
    this.basket$.subscribe(basket => {
      let giftbalance = 0;
      if (basket && basket.giftCard) {
        basket.giftCard.forEach((card: any) => {
          giftbalance = giftbalance + card.balance;
        });
        this.giftCardBalance = giftbalance;
      }
      if (basket && basket.basket.total === 0) {
        this.fullComp = true;
      }
      if (basket.basket.tip > 0) {
        this.tip.setValue(basket.basket.tip);
      }
      if (basket.basket.tip > 0) {
        this.tip.disable();
      } else {
        this.tip.enable();
      }
    });
    const basket = this.store.selectSnapshot((state: GlobalStateModel) => state.basket);
    if (basket && basket.giftCard) {
      this.giftTotal = this.gc.gcTotal(basket);
      this.needsSecondPayment = basket.giftCard && basket.basket.total > this.giftTotal;
      this.firstPaymentMethod.setValue('giftcard');
      if (basket.giftCard.length === 1) {
        this.firstGiftCardForm.get('cardNumber').setValue(basket.giftCard[0].cardnumber);
        this.firstGiftCardForm.get('pinNumber').setValue(basket.giftCard[0].pin);
      } else if (basket.giftCard.length === 2) {
        this.secondPaymentMethod.setValue('secondgiftcard');
        this.needsSecondPayment = true;
        this.firstGiftCardForm.get('cardNumber').setValue(basket.giftCard[0].cardnumber);
        this.firstGiftCardForm.get('pinNumber').setValue(basket.giftCard[0].pin);
        this.secondGiftCardForm.get('cardNumber').setValue(basket.giftCard[1].cardnumber);
        this.secondGiftCardForm.get('pinNumber').setValue(basket.giftCard[1].pin);
      }
    }
    if (basket && basket.billingInfo && basket.billingInfo.firstname && basket.billingInfo.lastname) {
      this.userForm.get('firstName').setValue(basket.billingInfo.firstname);
      this.userForm.get('lastName').setValue(basket.billingInfo.lastname);
    }
    const user = this.store.selectSnapshot((state: GlobalStateModel) => state.user);
    if (user && user.partySize) {
      this.selectedTableSize = user.partySize - 1;
    }
    this.directusExpo.getKioskCoupons().subscribe(coupons => {
      this.couponCollection = coupons;
    });
    this.time.valueChanges.subscribe(change => this.onTimeChange());
    this.timeSlots$
      .pipe(
        filter(ts => ts !== null),
        take(1)
      )
      .subscribe(ts => this.time.patchValue(0));
  }

  newPaymentSelected() {
    this.removeGiftCardIndex();
    this.needsSecondPayment = false;
    this.store.dispatch(new CleanGiftCard());
    this.giftTotal = 0;
    this.secondPaymentMethod.setValue('secondcreditcard');
  }

  tableSizeSelected(index: number) {
    this.selectedTableSize = index;
    this.store.dispatch(new SetPartySize(this.selectedTableSize + 1));
  }

  cleanChoices(choices: BasketChoice[]) {
    return choices.filter(choice => {
      return !(
        choice.name.toLowerCase().includes('included') || choice.name.toLowerCase().includes('additional toppings')
      );
    });
  }

  deleteItem(product: BasketProduct) {
    this.store.dispatch(new RemoveProductFromBasket(product.id.toString())).subscribe(() => {
      this.store.dispatch(new GetTimeSlots(false));
      const selectElement = document.getElementById('time-select') as HTMLSelectElement;
      selectElement.value = 'Select Time';
    });
  }

  editItem(product: BasketProduct) {
    this.router.navigate(['kiosk/product/' + product.productId], {
      queryParams: { edit: true, basketProd: product.id },
      replaceUrl: true
    });
  }

  // Remove gift card showing from checkout-bag upon switching to another payment method
  removeGiftCard() {
    const basket = this.store.selectSnapshot((state: GlobalStateModel) => state.basket);
    if (basket.giftCard) {
      this.store.dispatch(new CleanGiftCard());
    }
  }

  onTimeChange() {
    if (this.time.value === 'Select Time') {
      this.buttonAvailable = false;
      return;
    }
    const basket = this.store.selectSnapshot((state: GlobalStateModel) => state.basket);
    const selected = basket.timeSlots[this.time.value].value;
    const isManualFire = basket.basket.deliverymode === 'dinein';
    this.store.dispatch(new SetTimeWanted(selected.buckets[selected.buckets.length - 1].time, isManualFire)).subscribe(
      () => {
        this.store.dispatch(new SetBuckets(selected.buckets, isManualFire));
      },
      error => {
        // TODO: Fix bug where business hours are used when delivery hours should be used
        this.toastr.warning(error.error.message);
      }
    );
  }

  onCouponChange(couponCode: string) {
    this.applyCoupon(couponCode);
  }

  applyCoupon(couponCode: string) {
    this.store.dispatch(new ApplyCoupon(couponCode)).subscribe(
      () => {
        this.toastr.success('Coupon Applied');
        this.store.dispatch(new ValidateBasket());
      },
      error => {
        if (error.error.message) {
          this.toastr.warning(error.error.message);
        } else {
          this.toastr.warning('Coupon Code Is Invalid');
        }
      }
    );
  }

  removeCoupon() {
    this.store.dispatch(new RemoveCoupon()).subscribe(() => {
      this.store.dispatch(new ValidateBasket());
      const couponSelect = document.getElementById('coupon-select') as HTMLSelectElement;
      couponSelect.value = 'Select Comp Coupon';
    });
  }

  scanGiftCard(index: number) {
    // call datacap
    this.swipeCardModal(true);
    this.tranCloud.giftCardSale().subscribe(gc => {
      this.modalService.dismissAll();
      if (gc && gc.RStream && gc.RStream.CmdStatus === 'Success') {
        const temp = {
          cardNumber: gc.RStream.PrePaidTrack2
        };
        if (index === 0) {
          this.firstGiftCardForm.patchValue(temp);
        } else {
          this.secondGiftCardForm.patchValue(temp);
        }
      } else {
        this.toastr.warning('Card Denied');
      }
    });
  }

  checkBalance(index: number, dcTemp: any) {
    this.showBalance = true;
    const temp = dcTemp ? dcTemp : this.gc.gcDetails(index, this.firstGiftCardForm, this.secondGiftCardForm);
    const basket = this.store.selectSnapshot((state: GlobalStateModel) => state.basket);
    const cardAdded = this.gc.checkIfCardAdded(basket, temp);
    if (cardAdded) {
      return;
    }
    this.store.dispatch(new CheckBalance(temp)).subscribe(
      () => {
        this.modalService.dismissAll();
        const baskett = this.store.selectSnapshot((state: GlobalStateModel) => state.basket);
        let balance = 0;
        if (baskett && baskett.giftCard && baskett.giftCard[index] && baskett.giftCard[index].balance) {
          balance = baskett.giftCard[index].balance;
        }
        if (balance > 0) {
          this.store.dispatch(new ApplyGiftCard(true)).subscribe(() => {
            if (index === 0) {
              this.firstGiftCardForm.get('cardNumber').setValue(temp.cardnumber);
              this.firstGiftCardForm.get('pinNumber').setValue(temp.pin);
            } else {
              this.secondGiftCardForm.get('cardNumber').setValue(temp.cardnumber);
              this.secondGiftCardForm.get('pinNumber').setValue(temp.pin);
            }
            this.giftTotal = this.gc.gcTotal(baskett);
            if (baskett.giftCard && basket.basket.total > this.giftTotal) {
              this.needsSecondPayment = true;
            } else {
              if (index === 0) {
                this.needsSecondPayment = false;
              }
            }
          });
        } else {
          this.toastr.warning('There are no funds on this gift card');
        }
        this.balance = balance;
        // this.success('Gift Card Balance is $' + balance);
      },
      error => {
        this.modalService.dismissAll();
        this.showBalance = false;
        this.toastr.warning(error.error.message);
      }
    );
  }

  removeGiftCardIndex(index?: number) {
    const basket = this.store.selectSnapshot((state: GlobalStateModel) => state.basket);
    if (index) {
      this.store.dispatch(new RemoveGiftCard(index));
      if (index === 0 && basket.giftCard.length === 1) {
        this.firstGiftCardForm.get('cardNumber').setValue('');
        this.firstGiftCardForm.get('pinNumber').setValue('');
      } else if (index === 0 && basket.giftCard.length === 2) {
        this.firstGiftCardForm.get('cardNumber').setValue(this.secondGiftCardForm.get('cardNumber').value);
        this.firstGiftCardForm.get('pinNumber').setValue(this.secondGiftCardForm.get('pinNumber').value);
        this.secondGiftCardForm.get('cardNumber').setValue('');
        this.secondGiftCardForm.get('pinNumber').setValue('');
        // this.addNextGiftCard = !this.addNextGiftCard;
      } else if (index === 1) {
        this.secondGiftCardForm.get('cardNumber').setValue('');
        this.secondGiftCardForm.get('pinNumber').setValue('');
      }
      const baskett = this.store.selectSnapshot((state: GlobalStateModel) => state.basket);
      this.giftTotal = this.gc.gcTotal(baskett);
      this.needsSecondPayment = baskett.giftCard && baskett.basket.total > this.giftTotal;
      if (baskett && baskett.giftCard) {
        if (baskett.giftCard.length < 2) {
          // this.addNextGiftCard = false;
        }
      }
    } else {
      if (basket && basket.giftCard && basket.giftCard.length) {
        for (const x of basket.giftCard) {
          this.store.dispatch(new RemoveGiftCard(0));
        }
      }
    }
  }

  cashPaymentAdded(index: number) {
    const basket = this.store.selectSnapshot((state: GlobalStateModel) => state.basket);
    const amount = index ? this.secondCashPayment.get('amount').value : this.firstCashPayment.get('amount').value;
    if (amount) {
      this.cashPaymentTotal = amount;

      if (!index) {
        this.needsSecondPayment = basket.basket.total > amount;
      }
      this.changeDue = amount - (basket.basket.total - this.giftTotal);
      this.store.dispatch(new SetChange(this.changeDue));
    }
  }

  submitOrder() {
    this.isLoading = true;
    const selectElement = document.getElementById('time-select') as HTMLSelectElement;
    if (selectElement.value !== 'Select Time') {
      if (this.userForm.get('firstName').value && this.userForm.get('firstName').value) {
        const dineInName: DineInName = {
          firstname: this.userForm.get('firstName').value,
          lastname: this.userForm.get('lastName').value
        };
        localStorage.setItem('emailAddress', this.userForm.get('emailAddress').value);
        this.store.dispatch(new SetDineInName(dineInName));
      } else {
        this.isLoading = false;
        this.toastr.warning('Please provide a name');
        return;
      }
      const basket = this.store.selectSnapshot((state: GlobalStateModel) => state.basket);
      if (!basket.basketValidation) {
        this.isLoading = false;
        this.toastr.warning('Error validating basket.');
        return;
      }
      if (basket.basket.subtotal === basket.basket.coupondiscount && basket.basket.total === 0) {
        this.basketSubmit.billingmethod = 'cash';
      } else if (this.firstPaymentMethod.value === 'giftcard') {
        if (!this.needsSecondPayment) {
          this.singleGiftCardPayment();
        } else {
          // gg split pay
          if (basket.basket.total > this.giftTotal) {
            if (this.secondPaymentMethod.value === 'secondcreditcard') {
              // use manual cc
              if (
                this.creditCardPayment.value.cardNumber &&
                this.creditCardPayment.value.expDate &&
                this.creditCardPayment.value.cvvCode &&
                this.creditCardPayment.value.zipCode
              ) {
                this.splitPay
                  .multiplePayment(
                    this.giftTotal,
                    this.creditCardPayment,
                    null,
                    null,
                    true,
                    this.tableNumberForm.get('tableNumberControl').value
                  )
                  .subscribe(
                    () => {
                      this.isLoading = false;
                      this.toastr.success('The Order has been completed!');
                      this.router.navigateByUrl('/kiosk/confirmation');
                    },
                    error => {
                      this.isLoading = false;
                      if (error.error && error.error.message) {
                        this.toastr.warning(error.error.message);
                      } else {
                        this.toastr.warning(typeof error === 'string' ? error : error.message);
                      }
                    }
                  );
              } else {
                this.isLoading = false;
                this.toastr.warning('Please enter credit card information');
                return;
              }
            } else if (this.secondPaymentMethod.value === 'secondgiftcard') {
              this.splitPay
                .multiplePayment(
                  this.giftTotal,
                  this.creditCardPayment,
                  null,
                  null,
                  true,
                  this.tableNumberForm.get('tableNumberControl').value
                )
                .subscribe(
                  () => {
                    this.isLoading = false;
                    this.toastr.success('The Order has been completed!');
                    this.router.navigateByUrl('/kiosk/confirmation');
                  },
                  error => {
                    this.isLoading = false;
                    if (error.error && error.error.message) {
                      this.toastr.warning(error.error.message);
                    } else {
                      this.toastr.warning(typeof error === 'string' ? error : error.message);
                    }
                  }
                );
            } else {
              this.splitPay
                .multiplePayment(
                  this.giftTotal,
                  null,
                  Number(basket.basket.total - this.giftTotal),
                  null,
                  true,
                  this.tableNumberForm.get('tableNumberControl').value
                )
                .subscribe(
                  () => {
                    this.isLoading = false;
                    this.toastr.success('The Order has been completed!');
                    this.router.navigateByUrl('/kiosk/confirmation');
                  },
                  error => {
                    this.isLoading = false;
                    if (error.error && error.error.message) {
                      this.toastr.warning(error.error.message);
                    } else {
                      this.toastr.warning(typeof error === 'string' ? error : error.message);
                    }
                  }
                );
            }
          } else {
            this.splitPay
              .multiplePayment(
                this.giftTotal,
                null,
                null,
                null,
                true,
                this.tableNumberForm.get('tableNumberControl').value
              )
              .subscribe(
                () => {
                  this.isLoading = false;
                  this.toastr.success('The Order has been completed!');
                  this.router.navigateByUrl('/kiosk/confirmation');
                },
                error => {
                  this.isLoading = false;
                  if (error.error && error.error.message) {
                    this.toastr.warning(error.error.message);
                  } else {
                    this.toastr.warning(typeof error === 'string' ? error : error.message);
                  }
                }
              );
          }
          return;
        }
      } else if (this.firstPaymentMethod.value === 'creditcard') {
        this.store.dispatch(new ValidateBasket('false', true)).subscribe(
          () => this.submitTrancloudBasket(),
          error => this.toastr.warning(error.error && error.error.message ? error.error.message : error)
        );
        return;
      } else if (this.firstPaymentMethod.value === 'paywithcash') {
        if (this.needsSecondPayment) {
          // use manual cc
          if (
            this.creditCardPayment.value.cardNumber &&
            this.creditCardPayment.value.expDate &&
            this.creditCardPayment.value.cvvCode &&
            this.creditCardPayment.value.zipCode
          ) {
            this.splitPay
              .multiplePayment(
                null,
                this.creditCardPayment,
                Number(this.cashPaymentTotal),
                null,
                true,
                this.tableNumberForm.get('tableNumberControl').value
              )
              .subscribe(
                () => {
                  this.isLoading = false;
                  this.toastr.success('The Order has been completed!');
                  this.router.navigateByUrl('/kiosk/confirmation');
                },
                error => {
                  this.isLoading = false;
                  if (error.error && error.error.message) {
                    this.toastr.warning(error.error.message);
                  } else {
                    this.toastr.warning(typeof error === 'string' ? error : error.message);
                  }
                }
              );
          } else {
            this.isLoading = false;
            this.toastr.warning('Please enter credit card information');
            return;
          }
          return;
        } else {
          if (this.cashPaymentTotal !== 0) {
            this.basketSubmit.billingmethod = 'cash';
          } else {
            this.isLoading = false;
            this.toastr.warning('Please enter a cash amount');
            return;
          }
        }
      } else {
        return;
      }
      this.store.dispatch(new SetBillingInfo(this.basketSubmit)).subscribe(() => {
        this.submitBasket();
      });
    } else {
      this.isLoading = false;
      this.toastr.warning('Please select a Time');
    }
  }

  singleGiftCardPayment() {
    this.store.dispatch(new ValidateBasket());
    this.basketSubmit.billingmethod = 'storedvalue';
    const basket = this.store.selectSnapshot((state: GlobalStateModel) => state.basket);
    this.basketSubmit.billingschemeid = basket.billingSchemes.find((sche: any) => sche.type === 'giftcard').id;
    this.basketSubmit.billingfields = [
      {
        name: 'number',
        value: this.firstGiftCardForm.value.cardNumber
      },
      {
        name: 'pin',
        value: this.firstGiftCardForm.value.pinNumber
      }
    ];
    this.basketSubmit.usertype = basket.billingInfo.usertype;
  }

  submitBasket() {
    this.store.dispatch(new SetBillingInfo(this.basketSubmit)).subscribe(() => {
      this.store
        .dispatch(new SubmitBasket({ tableNumber: this.tableNumberForm.get('tableNumberControl').value }))
        .subscribe(
          () => {
            this.isLoading = false;
            this.modalService.dismissAll();
            this.toastr.success('The order has been completed!');
            this.router.navigateByUrl('/kiosk/confirmation');
          },
          error => {
            this.isLoading = false;
            this.basketSubmit = { ...this.store.selectSnapshot(state => state.basket.billingInfo) };
            if (error.error && error.error.message) {
              this.toastr.warning(error.error.message);
            } else {
              this.toastr.warning(typeof error === 'string' ? error : error.message);
            }
          }
        );
    });
  }

  submitTrancloudBasket() {
    let modalRef;
    const modalOptions: NgbModalOptions = { centered: true, size: 'lg', windowClass: 'hideScroll' };
    modalRef = this.modalService.open(SwipeCardModalComponent, modalOptions);
    modalRef.componentInstance.isGiftCard = false;
    modalRef.componentInstance.xClicked.subscribe(() => {
      this.modalService.dismissAll();
      this.isLoading = false;
    });
    this.store.dispatch(new SetBillingInfo(this.basketSubmit)).subscribe(() => {
      this.store
        .dispatch(new SubmitTrancloudBasket({ tableNumber: this.tableNumberForm.get('tableNumberControl').value }))
        .subscribe(
          () => {
            this.isLoading = false;
            this.modalService.dismissAll();
            this.toastr.success('The order has been completed!');
            this.router.navigateByUrl('/kiosk/confirmation');
          },
          error => {
            this.isLoading = false;
            this.modalService.dismissAll();
            this.basketSubmit = { ...this.store.selectSnapshot(state => state.basket.billingInfo) };
            if (error.error && error.error.message) {
              this.toastr.warning(error.error.message);
            } else {
              this.toastr.warning(typeof error === 'string' ? error : error.message);
            }
          }
        );
    });
  }

  checkAndSwitchMask(input: any) {
    if (input.target.value.length > 17 && this.currentCardMask === this.amExMask) {
      this.currentCardMask = this.regularMask;
      this.currentCVVMask = '000';
    } else if (this.currentCardMask === this.regularMask && input.target.value.length < 19) {
      this.currentCardMask = this.amExMask;
      this.currentCVVMask = '0000';
    }
  }

  swipeCardModal(isGiftCard: boolean) {
    let modalRef;
    const modalOptions: NgbModalOptions = { centered: true, size: 'lg', windowClass: 'hideScroll' };
    modalRef = this.modalService.open(SwipeCardModalComponent, modalOptions);
    modalRef.componentInstance.isGiftCard = isGiftCard;
    modalRef.componentInstance.xClicked.subscribe(() => {
      this.modalService.dismissAll();
    });
  }

  alcoholModal() {
    const menu = this.store.selectSnapshot((state: GlobalStateModel) => state.restaurant.menu);
    const order = this.store.selectSnapshot((state: GlobalStateModel) => state.basket.basket);
    const orderItems: Product[] = [];
    menu.categories.forEach((category: Category) => {
      category.products.forEach((product: Product) => {
        if (order.products.find((item: BasketProduct) => String(item.productId) === String(product.id))) {
          orderItems.push(product);
        }
      });
    });
    if (orderItems.some(item => item.alcoholstatus !== AlcoholStatus.NONE)) {
      let modalRef;
      const modalOptions: NgbModalOptions = { centered: true, size: 'lg', windowClass: 'hideScroll' };
      modalRef = this.modalService.open(AlcoholStatusModalComponent, modalOptions);
      modalRef.componentInstance.xClicked.subscribe(() => {
        this.modalService.dismissAll();
      });
    }
  }

  applyTip(amount: number): void {
    this.store.dispatch(new SetTipAmount(amount.toFixed(2)));
  }

  removeTip(): void {
    this.tip.setValue(null);
    this.store.dispatch(new SetTipAmount((0).toFixed(2)));
  }

  get couponCode() {
    return this.couponPromoForm.get('couponCode').value;
  }

  get time() {
    return this.timeForm.get('time');
  }

  get tip() {
    return this.tipForm.get('tip');
  }
}
