import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { Order } from 'src/assets/chepri-modules/src/models/olo.order';
import { DateTime } from '../../../../assets/chepri-modules/src/models/DateTime';
import { Router } from '@angular/router';
import moment from 'moment';
import { PrinterService } from '@app/providers/expo/printer/printer.service';
import { DirectusExpoProvider } from '@app/providers/expo/directus/directus-expo.provider';
import { EmailService } from '@app/providers/expo/email/email.service';
import { IDirectusExpoOrder } from '@app/models/expo-order.interface';
import { CurrencyPipe } from '@angular/common';
import { filter } from 'rxjs/operators';
import { BillingInfo } from '../../../../assets/chepri-modules/src/models/olo.billinginfo';
import { TruncatePipe } from '@app/shared/pipes/truncate.pipe';
import { BasketStateModel } from '@app/store/state/basket.state';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['../customer-kiosk.component.scss']
})
export class ConfirmationComponent implements OnInit {
  @Select((state: any) => state.basket) basket$: Observable<BasketStateModel>;
  orderType: string;

  readyTime: Date = new Date();

  constructor(
    private router: Router,
    private printer: PrinterService,
    private expo: DirectusExpoProvider,
    private email: EmailService,
    private store: Store,
    private currencyPipe: CurrencyPipe,
    private truncatePipe: TruncatePipe
  ) {}

  ngOnInit(): void {
    this.setConfirmationTimeout();
    this.basket$.pipe(filter(pb => pb !== null)).subscribe(basket => {
      const order = basket.previousBasket;
      this.orderType = order.deliverymode;
      this.readyTime = DateTime.fromOlo(order.readytime).moment.toDate();
      this.expo.getExpoOrderByOrderId(order.id).subscribe(res => {
        this.store
          .selectOnce(state => state.basket)
          .subscribe((basketState: BasketStateModel) => {
            const expoOrder = res[0];
            const emailAddress = localStorage.getItem('emailAddress');
            localStorage.removeItem('emailAddress');
            if (emailAddress && emailAddress !== '') {
              this.email.postConfirmation({ expoOrder, basket: basketState, emailAddress }).subscribe(() => {});
            }
            // this.printer.onSendMessageCanvas(
            //   this.generateReceipt.bind(this, [order, expoOrder, basketState.billingInfo, basketState])
            // );
          });
      });
    });
  }

  setConfirmationTimeout() {
    setTimeout(() => {
      this.startOver();
    }, 15000);
  }

  startOver() {
    this.router.navigate(['cks', 'home']);
  }

  // private generateReceipt(
  //   prevBasket: Order,
  //   expoOrder: IDirectusExpoOrder,
  //   billingInfo: BillingInfo,
  //   basketState: BasketStateModel
  // ) {
  //   const formattedDateTime = moment(
  //     prevBasket.deliverymode === 'dinein' ? expoOrder.ready_time : prevBasket.readytime,
  //     'YYYY-MM-DD HH:mm:ss'
  //   ).format('MM/DD/YYYY - hh:mm');
  //   const cardData = this.store.selectSnapshot(state => state.basket.trancloudInfo);
  //   const formattedSubtotal = this.currencyPipe.transform(prevBasket.subtotal, 'USD');
  //   let formattedDiscount = '';
  //   if (prevBasket.discounts && prevBasket.discounts.length) {
  //     formattedDiscount = this.currencyPipe.transform(prevBasket.discounts[0].amount, 'USD');
  //   }
  //   const formattedTax = this.currencyPipe.transform(prevBasket.salestax, 'USD');
  //   const formattedTotal = this.currencyPipe.transform(prevBasket.total, 'USD');
  //
  //   this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 3);
  //
  //   // this.printer.drawAlignedText('center', this.printer.centerPosition, 'Punch Neapolitan Pizza');
  //   // this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1)
  //
  //   this.printer.drawAlignedText('center', this.printer.centerPosition, prevBasket.vendorname);
  //   this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 2);
  //
  //   this.printer.drawAlignedText(
  //     'center',
  //     this.printer.centerPosition,
  //     billingInfo.firstname + ' ' + billingInfo.lastname
  //   );
  //   this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);
  //
  //   this.printer.drawAlignedText('center', this.printer.centerPosition, 'Order #' + prevBasket.oloid);
  //   this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);
  //
  //   this.printer.drawAlignedText('center', this.printer.centerPosition, formattedDateTime);
  //   this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 3);
  //
  //   prevBasket.products.forEach((product: any) => {
  //     const formattedName = this.truncatePipe.transform(product.name, 27);
  //     const formattedPrice = this.currencyPipe.transform(product.totalcost, 'USD');
  //     this.printer.drawAlignedText('left', this.printer.leftPosition, product.quantity + ' - ' + formattedName);
  //     this.printer.drawAlignedText('right', this.printer.rightPosition, formattedPrice);
  //     this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);
  //     if (product.choices && product.choices.length) {
  //       product.choices.forEach((choice: any) => {
  //         if (
  //           !choice.name.includes('Included') &&
  //           choice.name !== 'Additional Toppings' &&
  //           choice.name !== 'Additional Ingredients' &&
  //           choice.name !== 'Mozzarella (Standard, No Charge)'
  //         ) {
  //           this.printer.drawAlignedText(
  //             'left',
  //             this.printer.leftPosition,
  //             '     • ' + this.truncatePipe.transform(choice.name, 30)
  //           );
  //           this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);
  //         }
  //       });
  //     }
  //   });
  //
  //   this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);
  //
  //   this.printer.context.fillRect(0, this.printer.cursor - 2, this.printer.canvasData.receiptWidth, 2); // Underline
  //   this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 2);
  //
  //   this.printer.drawAlignedText('left', this.printer.leftPosition, 'Subtotal');
  //   this.printer.drawAlignedText('right', this.printer.rightPosition, formattedSubtotal);
  //   this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);
  //
  //   this.printer.drawAlignedText('left', this.printer.leftPosition, 'Discount');
  //   if (prevBasket.discounts && prevBasket.discounts.length) {
  //     this.printer.drawAlignedText('right', this.printer.rightPosition, '-' + formattedDiscount);
  //   }
  //   this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);
  //
  //   this.printer.drawAlignedText('left', this.printer.leftPosition, 'Tax');
  //   this.printer.drawAlignedText('right', this.printer.rightPosition, formattedTax);
  //   this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);
  //
  //   this.printer.drawAlignedText('left', this.printer.leftPosition, 'Total');
  //   this.printer.drawAlignedText('right', this.printer.rightPosition, formattedTotal);
  //   this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 3);
  //
  //   if (cardData) {
  //     this.printer.drawAlignedText('left', this.printer.leftPosition, cardData.cardType);
  //     this.printer.drawAlignedText('right', this.printer.rightPosition, cardData.cardNumber);
  //     this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);
  //     this.printer.drawAlignedText('left', this.printer.leftPosition, `  Auth: ${cardData.authCode}`);
  //     this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);
  //     this.printer.drawAlignedText('left', this.printer.leftPosition, `  Ref #: ${cardData.refNo}`);
  //     this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 2);
  //   }
  //
  //   this.printer.drawAlignedText('left', this.printer.leftPosition, 'Paid with:');
  //
  //   const paymentMethods =
  //     (basketState.giftCard !== null
  //       ? 'GIFT CARD'
  //       : basketState.billingInfo.billingmethod || basketState.extraCard
  //       ? 'CASH'
  //       : 'CREDIT CARD') +
  //     (basketState.extraCard
  //       ? ' / CREDIT CARD'
  //       : basketState.giftCard !== null &&
  //         basketState.giftCard.length === 1 &&
  //         basketState.giftCard[0].balance < prevBasket.total
  //       ? ' / CASH'
  //       : '');
  //
  //   console.log('Payment method(s): ' + paymentMethods);
  //   console.log('Basket: ', basketState);
  //
  //   this.printer.drawAlignedText('right', this.printer.rightPosition, paymentMethods);
  //   this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 2);
  // }
}
