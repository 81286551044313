import {
  AddFavoriteLocation,
  AddFavoriteMeal,
  ClearKioskUserData,
  CreateSavedProduct,
  DeleteAccount,
  DeleteCard,
  DeleteFavoriteLocation,
  DeleteFavoriteMeal,
  DeleteSavedProduct,
  ForgotPassword,
  GetFavoriteMeals,
  GetFavStore,
  GetOloBillingAccounts,
  GetRecentOrders,
  GetUser,
  Login,
  Logout,
  SetDefaultCard,
  SetDineInName,
  SetPartySize,
  SetRole,
  SetSavedProducts,
  SetSelectedFave,
  SetUser,
  SetUserPreferences,
  SetWiselyContactLists,
  SetWiselyGuest,
  UpdateFavoriteLocation,
  UpdateFavoriteMeal,
  UpdatePassword,
  UpdateUserPreferences,
  UpsertWiseGuest
} from './../actions/user.action';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { AddUser, UpdateCard, UpdateUser } from '../actions/user.action';
import { catchError, concatMap, filter, map, switchMap, tap, toArray } from 'rxjs/operators';
import { from, of, throwError } from 'rxjs';
import { User } from 'src/assets/chepri-modules/src/models/olo.user';
import { DineInName } from 'src/assets/chepri-modules/src/models/dineinname';
import { Card } from 'src/assets/chepri-modules/src/models/olo.card';
import { UserService } from '@app/providers/user.service';
import { CredentialsService } from '@app/core';
import { recentorders } from 'src/assets/chepri-modules/src/models/olo.recentorders';
import { FavoritesOrderModel } from '../../../assets/chepri-modules/src/lib/favorite-order-name/favorites-order.model';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { UserCommunicationPreferences } from '../../../assets/chepri-modules/src/models/olo.usercommunicationpreferences';
import { BooleanString } from '../../../assets/chepri-modules/src/models/BooleanString';
import {
  RequestCreateSavedProduct,
  RequestCreateSavedProductChoice,
  ResponseRetrieveSavedProductsSavedProduct
} from '@app/models/saved-products.olo';
import { Modifiers } from '@lib/models/olo.modifiers';
import { Option } from '@lib/models/olo.option';
import { OptionGroup } from '@lib/models/olo.optiongroup';
import { BasketChoice } from '@lib/models/olo.basketproduct';
import { ProductService } from '@app/providers/product.service';
import { BillingAccount } from '@app/models/billingaccount.interface';
import { SavedAccount } from '@app/models/saved-account.olo';
import { User as WiselyUser } from '@app/models/wisely/get-guest-info.interface';
import { WiselyService } from '@app/providers/wisely.service';
import { GetContactListsResponse } from '@app/models/wisely/get-contact-lists.interface';
import { EmptyBasket } from '@app/store/actions/basket.action';

export class UserStateModel {
  info: User;
  cardInfo: Card;
  recentOrders: recentorders;
  favoriteStore: any[];
  favMeals: any[];
  selectedFave: any;
  addresses: string[];
  activity: any[];
  billing?: { billingaccounts: SavedAccount[] };
  partySize: number;
  dineInName: DineInName;
  role: string;
  isMaster: boolean;
  printerIPs: string[];
  cardReaders: any[];
  userPreferences: UserCommunicationPreferences;
  savedProducts: ResponseRetrieveSavedProductsSavedProduct[];
  wiselyGuest: WiselyUser;
  contactLists: GetContactListsResponse;
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    info: null,
    cardInfo: null,
    recentOrders: null,
    favoriteStore: null,
    favMeals: null,
    selectedFave: null,
    addresses: null,
    activity: null,
    billing: null,
    partySize: 1,
    dineInName: null,
    role: null,
    isMaster: false,
    printerIPs: null,
    cardReaders: null,
    userPreferences: null,
    savedProducts: null,
    wiselyGuest: null,
    contactLists: null
  }
})
@Injectable({
  providedIn: 'root'
})
export class UserState {
  @Selector()
  static info(state: UserStateModel) {
    return state.info;
  }

  @Selector()
  static favoriteStore(state: UserStateModel) {
    return state.favoriteStore;
  }

  @Selector()
  static favMeals(state: UserStateModel) {
    return state.favMeals;
  }

  @Selector()
  static cardInfo(state: UserStateModel) {
    return state.cardInfo;
  }

  @Selector()
  static recentOrders(state: UserStateModel) {
    return state.recentOrders;
  }

  @Selector()
  static addresses(state: UserStateModel) {
    return state.addresses;
  }

  constructor(
    private user: UserService,
    private store: Store,
    private credentials: CredentialsService,
    private toast: ToastrService,
    private product: ProductService,
    private wisely: WiselyService
  ) {}

  @Action(AddUser)
  add({ dispatch, getState, patchState }: StateContext<UserStateModel>, { payload }: AddUser) {
    return this.user.create(payload).pipe(
      tap((info: User) => {
        patchState({ info });

        dispatch(new UpdateUserPreferences(payload.emailoptin, payload.smsoptin));
        dispatch(new SetSavedProducts());
        this.credentials.setCredentials({ username: info.emailaddress, token: info.authtoken, isGuest: false }, true);
      })
    );
  }

  @Action(Login)
  loginUser({ patchState, dispatch }: StateContext<UserStateModel>, { userAuth, remember }: Login) {
    return this.user.login(userAuth).pipe(
      switchMap((data: any) => {
        this.credentials.setCredentials(
          { username: data.user.emailaddress, token: data.user.authtoken, isGuest: false },
          remember
        );
        dispatch(new SetUserPreferences(data.user.authtoken));
        this.credentials.setUser(data.user, remember);
        patchState({
          info: data.user
        });
        dispatch([new SetWiselyContactLists(), new SetWiselyGuest(data.user.emailaddress)]);
        return dispatch(new SetSavedProducts());
      }),
      catchError(err => {
        if (err.error && err.error.message) {
          this.toast.warning(err.error.message);
        } else {
          this.toast.error('Oops, something went wrong');
        }
        return throwError(err);
      })
    );
  }

  @Action(SetUser)
  setUser({ patchState, dispatch }: StateContext<UserStateModel>, { user }: SetUser) {
    patchState({
      info: user
    });
    return dispatch(new SetSavedProducts());
  }

  @Action(GetUser)
  getUser({ patchState, dispatch }: StateContext<UserStateModel>, { token }: GetUser) {
    return this.user.getUser(token).pipe(
      tap(data => {
        dispatch(new SetUserPreferences(token));
        patchState({
          info: data
        });
        dispatch(new SetSavedProducts());
      })
    );
  }

  @Action(SetPartySize)
  SetPartySize({ patchState }: StateContext<UserStateModel>, payload: SetPartySize) {
    patchState({
      partySize: payload.size
    });
  }

  @Action(SetDineInName)
  SetDineInName({ patchState }: StateContext<UserStateModel>, payload: SetDineInName) {
    patchState({
      dineInName: payload.payload
    });
  }

  @Action(ClearKioskUserData)
  ClearKioskUserData({ patchState }: StateContext<UserStateModel>, payload: ClearKioskUserData) {
    patchState({
      dineInName: null,
      partySize: null
    });
  }

  @Action(Logout)
  logout({ patchState }: StateContext<UserStateModel>) {
    this.credentials.setCredentials(null, false);
    this.store.dispatch(new EmptyBasket());
    patchState({
      info: null,
      cardInfo: null,
      recentOrders: null,
      favoriteStore: null,
      favMeals: null,
      addresses: null,
      activity: null,
      billing: null,
      savedProducts: null
    });
  }

  @Action(UpdatePassword)
  updatePassword({ patchState }: StateContext<UserStateModel>, { currentPassword, newPassword }: UpdatePassword) {
    return this.user.changePassword(currentPassword, newPassword).pipe(
      tap(() => {
        // this.store.dispatch( new Logout() );
      })
    );
  }

  @Action(ForgotPassword)
  forgotPassword({ patchState }: StateContext<UserStateModel>, { emailAddress }: ForgotPassword) {
    return this.user.forgotPassword(emailAddress).pipe(
      tap(() => {
        // Something?
      })
    );
  }

  @Action(UpdateUser)
  update({ getState, patchState }: StateContext<UserStateModel>, { info }: UpdateUser) {
    return this.user.updateInfo(info).pipe(
      tap((res: User) => {
        patchState({
          info: res
        });
      })
    );
  }

  @Action(UpdateCard)
  updateCard({ getState, patchState }: StateContext<UserStateModel>, { payload }: UpdateCard) {
    patchState({
      cardInfo: payload
    });
  }

  @Action(SetSelectedFave)
  SetSelectedFave(ctx: StateContext<UserStateModel>, payload: any) {
    ctx.setState({
      ...ctx.getState(),
      selectedFave: payload.payload
    });
  }

  @Action(UpdateFavoriteMeal)
  updateFavoriteMeal({ getState, patchState }: StateContext<UserStateModel>, { payload }: UpdateFavoriteMeal) {
    patchState({
      favMeals: payload
    });
  }

  @Action(DeleteFavoriteMeal)
  async deleteFavoriteMeal({ getState, patchState }: StateContext<UserStateModel>, { payload }: DeleteFavoriteMeal) {
    return await this.user.deleteFavoriteMeal(payload).toPromise();
  }

  @Action(DeleteFavoriteLocation)
  deleteFavoriteLocation({ getState, patchState }: StateContext<UserStateModel>, { payload }: DeleteFavoriteLocation) {
    return this.user.deleteFavoriteLocation(payload).pipe(
      tap(() => {
        const state = getState();
        patchState({
          favoriteStore: [...state.favoriteStore]
        });
        this.store.dispatch(new GetFavStore());
      })
    );
  }

  @Action(UpdateFavoriteLocation)
  updateFavoriteLocation(
    { getState, patchState }: StateContext<UserStateModel>,
    { oldOnes, restaurant_id }: UpdateFavoriteLocation
  ) {
    return this.user
      .updateRestaruantFave(oldOnes)
      .pipe(
        tap(result => {
          const state = getState();
          patchState({
            favoriteStore: [...state.favoriteStore, result]
          });
        })
      )
      .forEach(() => {
        return this.user.saveResturantFave(restaurant_id).pipe(
          tap(result => {
            const state = getState();
            patchState({
              favoriteStore: [...state.favoriteStore, result]
            });
          })
        );
      });
  }

  @Action(AddFavoriteLocation)
  addFavoriteLocation(
    { getState, patchState, dispatch }: StateContext<UserStateModel>,
    { resturant_id }: AddFavoriteLocation
  ) {
    return this.user.saveResturantFave(resturant_id).pipe(
      tap(result => {
        const state = getState();
        patchState({
          favoriteStore: [...state.favoriteStore, result]
        });
      })
    );
  }

  @Action(AddFavoriteMeal)
  addFavoriteMeal(ctx: StateContext<UserStateModel>, { payload }: AddFavoriteMeal) {
    return this.user.saveFavoriteBasket(payload).pipe(
      tap((result: any) => {
        const state = ctx.getState();
        ctx.setState({
          ...ctx.getState(),
          favMeals: result
        });
      })
    );
  }

  @Action(GetRecentOrders)
  getRecentOrders({ patchState }: StateContext<UserStateModel>) {
    const user = this.store.selectSnapshot(UserState);
    if (user.info) {
      const token = this.store.selectSnapshot(state => state.user.info.authtoken);
      return this.user.getRecentOrders(token).pipe(
        tap((recentOrders: recentorders) => {
          patchState({ recentOrders });
        })
      );
    } else {
      return;
    }
  }

  @Action(GetFavoriteMeals)
  getFavoriteHistory({ getState, patchState }: StateContext<UserStateModel>) {
    const token = this.store.selectSnapshot(state => state.user.info.authtoken);
    return this.user.getFavoriteMeals(token).pipe(
      tap((favMeals: any) => {
        patchState({ favMeals });
      })
    );
  }

  @Action(GetFavStore)
  getFavStore({ getState, patchState }: StateContext<UserStateModel>) {
    const token = this.store.selectSnapshot(state => state.user.info.authtoken);
    return this.user.getFavStore(token).pipe(
      tap((favoriteStore: any) => {
        patchState({ favoriteStore });
      })
    );
  }

  @Action(SetDefaultCard)
  setDefaultCard(
    { patchState, dispatch, getState }: StateContext<UserStateModel>,
    { accountidstring }: SetDefaultCard
  ) {
    const token = this.store.selectSnapshot(state => state.user.info.authtoken);
    return this.user
      .setCreditCardDefault(token, accountidstring)
      .pipe(
        tap((billing: any) => {
          dispatch(new GetOloBillingAccounts());
          // patchState({ billing });
        })
      )
      .subscribe(
        (res: any) => {
          this.toast.success('Default card updated successfully!');
        }
        // err => {
        //   this.toast.warning('There was a issue authorizing your request.');
        //   return throwError(err);
        // }
      );
  }

  @Action(DeleteCard)
  deleteCard({ patchState, dispatch, getState }: StateContext<UserStateModel>, { accountidstring }: DeleteCard) {
    const token = this.store.selectSnapshot(state => state.user.info.authtoken);
    const cards = getState().billing.billingaccounts;
    const deleteCard = cards.find(card => card.accountidstring === accountidstring);
    return from(cards).pipe(
      filter(c => `${c.description}${c.expiration}` === `${deleteCard.description}${deleteCard.expiration}`),
      concatMap(card => {
        return this.user.deleteCreditCard(token, card.accountidstring);
      }),
      toArray(),
      tap(() => dispatch(new GetOloBillingAccounts())),
      tap(() => this.toast.success('Card deleted successfully!'))
    );
  }

  @Action(GetOloBillingAccounts)
  getOloBillingAccounts({ patchState }: StateContext<UserStateModel>, action: GetOloBillingAccounts) {
    const token = this.store.selectSnapshot(state => state.user.info.authtoken);
    return this.user.getBillingAccounts(token).pipe(
      tap((billing: any) => {
        patchState({ billing });
      })
    );
  }

  @Action(SetRole)
  setRole(ctx: StateContext<UserStateModel>, action: SetRole) {
    return ctx.patchState({
      role: action.user.role as string,
      isMaster: action.user.role === '07fbb946-5744-44d6-b805-302fd48b261f',
      printerIPs: action.user.printer_ip,
      cardReaders: action.user.card_readers
    });
  }

  @Action(SetUserPreferences)
  setUserPreferences(ctx: StateContext<UserStateModel>, action: SetUserPreferences) {
    return this.user.getUserCommunicationPreferences(action.authToken).pipe(
      map(preferences => {
        return ctx.patchState({
          userPreferences: preferences
        });
      })
    );
  }

  @Action(UpdateUserPreferences)
  updateUserPreferences(ctx: StateContext<UserStateModel>, action: UpdateUserPreferences) {
    const body: UserCommunicationPreferences = {
      marketingsms:
        action.smsOptIn !== undefined
          ? action.smsOptIn
            ? BooleanString.True
            : BooleanString.False
          : ctx.getState().userPreferences.marketingsms,
      optin: action.emailOptIn ? BooleanString.True : BooleanString.False,
      emailreceipts: ctx.getState().userPreferences.emailreceipts,
      upsell: ctx.getState().userPreferences.upsell,
      followups: ctx.getState().userPreferences.followups
    };
    return this.user.updateUserCommunicationPreferences(ctx.getState().info.authtoken, body).pipe(
      map(preferences => {
        return ctx.patchState({
          userPreferences: preferences
        });
      })
    );
  }

  @Action(SetSavedProducts)
  setSavedProducts(ctx: StateContext<UserStateModel>, action: SetSavedProducts) {
    return this.user.retrieveSavedProducts(ctx.getState().info.authtoken).pipe(
      map(savedProducts => {
        return ctx.patchState({
          savedProducts: savedProducts.savedproducts.filter(product => product.product.availability.available)
        });
      })
    );
  }

  @Action(CreateSavedProduct)
  createSavedProduct(ctx: StateContext<UserStateModel>, action: CreateSavedProduct) {
    return this.product.getProductModifiers(action.product.id).pipe(
      switchMap(modifiers => {
        const product: RequestCreateSavedProduct = {
          chainproductid: String(action.product.chainproductid),
          quantity: action.quantity,
          specialinstructions: action.specialInstructions,
          recipient: action.recipientName,
          choices: this.findSelectedIngredients(modifiers, action.options).map((option: Option) =>
            this.optionToRequestCreateSavedProductChoice(option)
          )
        };
        return this.user
          .createSavedProduct(ctx.getState().info.authtoken, action.name, product)
          .pipe(tap(_ => ctx.dispatch(new SetSavedProducts())));
      })
    );
  }

  @Action(DeleteSavedProduct)
  deleteSavedProduct(ctx: StateContext<UserStateModel>, action: DeleteSavedProduct) {
    return this.user
      .deleteSavedProduct(ctx.getState().info.authtoken, action.savedProductID)
      .pipe(tap(_ => ctx.dispatch(new SetSavedProducts())));
  }

  @Action(DeleteAccount)
  deleteAccount(ctx: StateContext<UserStateModel>, action: DeleteAccount) {
    return this.user.deleteUser(ctx.getState().info.authtoken).pipe(
      switchMap(() => {
        return ctx.dispatch(new Logout());
      })
    );
  }

  @Action(SetWiselyGuest)
  setWiselyGuest(ctx: StateContext<UserStateModel>, action: SetWiselyGuest) {
    return this.wisely.getGuestInfo(action.email).pipe(
      map(guest => {
        if (guest.user) {
          return ctx.patchState({
            wiselyGuest: guest.user
          });
        } else {
          return ctx.patchState({
            wiselyGuest: null
          });
        }
      }),
      catchError(() => {
        return of(
          ctx.patchState({
            wiselyGuest: null
          })
        );
      })
    );
  }

  @Action(SetWiselyContactLists)
  setWiselyContactLists(ctx: StateContext<UserStateModel>, action: SetWiselyContactLists) {
    return this.wisely.getContactLists().pipe(
      map(contactLists => {
        return ctx.patchState({
          contactLists
        });
      }),
      catchError(() => {
        return of(
          ctx.patchState({
            contactLists: null
          })
        );
      })
    );
  }

  @Action(UpsertWiseGuest)
  upsertWiseGuest(ctx: StateContext<UserStateModel>, action: UpsertWiseGuest) {
    return this.wisely.upsertGuest(action.guest).pipe(
      map(guest => {
        return ctx.dispatch(new SetWiselyGuest(guest.guest.email));
      }),
      catchError(() => {
        return of(
          ctx.patchState({
            wiselyGuest: null
          })
        );
      })
    );
  }

  private findSelectedIngredients(modifiers: Modifiers, choices: number[]): Option[] {
    let selectedIngredients: Option[] = [];
    if (modifiers.optiongroups?.length) {
      modifiers.optiongroups?.forEach((optionGroup: OptionGroup) => {
        selectedIngredients = selectedIngredients.concat(
          this.findSelectedOptionsInChildOptionGroups(optionGroup, choices)
        );
      });
    }
    return selectedIngredients;
  }

  private findSelectedOptionsInChildOptionGroups(optionGroup: OptionGroup, choices: number[]): Option[] {
    let selectedOptions: Option[] = [];
    optionGroup.options.forEach((option: Option) => {
      if (choices.find(choice => choice === option.id)) {
        selectedOptions.push(option);
      }
      if (option.children) {
        option.modifiers.forEach((childOptionGroup: OptionGroup) => {
          selectedOptions = selectedOptions.concat(
            this.findSelectedOptionsInChildOptionGroups(childOptionGroup, choices)
          );
        });
      }
    });
    return selectedOptions;
  }

  private optionToRequestCreateSavedProductChoice(option: Option): RequestCreateSavedProductChoice {
    return {
      chainchoiceid: String(option.chainoptionid),
      quantity: 1
    };
  }
}
