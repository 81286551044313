import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { Basket, BasketValidation } from '../../../../assets/chepri-modules/src/models/olo.basket';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CurrencyPipe } from '@angular/common';
import { CapacityService } from '@app/providers/capacity.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CmsService } from '@app/providers/cms.service';
import { GiftCardService } from '@app/providers/giftCard.service';
import { SplitPaymentService } from '@app/providers/splitpayment.service';
import { TrancloudProviderService } from '@app/datacap-trancloud/services/trancloud-provider.service';
import { DirectusExpoProvider } from '@app/providers/expo/directus/directus-expo.provider';
import { BillingInfo } from '../../../../assets/chepri-modules/src/models/olo.billinginfo';
import {
  ApplyCoupon,
  CheckBalance,
  CleanGiftCard,
  GetBasket,
  GetBillingSchemes,
  RemoveCoupon,
  SetBillingInfo,
  SetOrderPlaceholder,
  SubmitBasket,
  SubmitTrancloudBasket,
  ValidateBasket
} from '@app/store/actions/basket.action';
import { SetBanner } from '@app/store/actions/app.action';
import { SwipeCardModalComponent } from '@app/components/swipe-card-modal/swipe-card-modal.component';
import { AbstractControl, FormControl, FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import moment from 'moment';
import { RestaurantState } from '@app/store/state/restaurant.state';
import { TimeoutModalComponent } from '../../../../assets/chepri-modules/src/lib/timeout-modal/timeout-modal.component';
import { CapacityPlaceholder } from '../../../../assets/chepri-modules/src/models/capacityplaceholder';
import { ClearKioskUserData } from '@app/store/actions/user.action';
import { take } from 'rxjs/operators';
import { DineInConfirmationModalComponent } from '@app/components/dine-in-confirmation-modal/dine-in-confirmation-modal.component';
import { BasketStateModel } from '@app/store/state/basket.state';
import { GlobalStateModel } from '@app/store/state.model';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['../customer-kiosk.component.scss']
})
export class CustomerKioskCheckoutComponent implements OnInit, OnDestroy {
  @Select((state: any) => state.basket.basket) basket$: Observable<Basket>;
  @Select((state: any) => state.basket.basketValidation) basketValidation$: Observable<BasketValidation>;
  @Select((state: any) => state.app.accessibilityMode) accessibilityMode$: Observable<boolean>;
  @ViewChild('gcModal') gcModal: TemplateRef<any>;

  // basketValidation$: Observable<BasketValidation> = of({
  //   basketid: '73d6f9c2-60cb-474c-bb36-9686bf1dea83',
  //   contextualpricing: {
  //     isposvalidated: true,
  //     issyndicated: true
  //   },
  //   tax: 2.25,
  //   taxes: [
  //     {
  //       label: 'Sales Tax',
  //       tax: 1.25
  //     }
  //   ],
  //   customerhandoffcharge: 1.95,
  //   fees: [
  //     {
  //       amount: 0.75,
  //       description: 'Service fee',
  //       note: 'Necessary service fee'
  //     }
  //   ],
  //   donations: [
  //     {
  //       id: 3891,
  //       amount: 5.75,
  //       description: 'Donation toward dog shelters.',
  //       note: 'Dog shelter donation.'
  //     }
  //   ],
  //   subtotal: 18.28,
  //   total: 22.15,
  //   readytime: '20210125 19:20',
  //   totalfees: 2.25,
  //   totaldonations: 5,
  //   upsellgroups: [
  //     {
  //       title: 'Desserts',
  //       items: [
  //         {
  //           id: 3151658,
  //           name: 'Cookie',
  //           cost: '1.50',
  //           shortdescription: 'Oatmeal Raisin Cookie',
  //           minquantity: 1,
  //           maxquantity: 12
  //         }
  //       ]
  //     }
  //   ],
  //   posreferenceresponse: 'string'
  // });

  ccLoading = false;
  gcLoading = false;
  applyingGiftCard = false;
  timeLimitLoading = true;
  timeLeft: string;
  intervalTimer: NodeJS.Timeout;
  giftCardForm: UntypedFormGroup = new UntypedFormGroup({
    gcNumber: new UntypedFormControl(''),
    gcPin: new UntypedFormControl('')
  });

  couponCode = new FormControl('');

  errorMessage: string = '';
  couponLoading = false;

  private billingInfo: BillingInfo = new BillingInfo();
  private modalOptions: NgbModalOptions = {
    centered: true,
    size: 'lg',
    windowClass: 'hideScroll',
    backdrop: 'static',
    keyboard: false
  };
  constructor(
    private router: Router,
    private store: Store,
    private toastr: ToastrService,
    private currencyPipe: CurrencyPipe,
    private capacityService: CapacityService,
    private modalService: NgbModal,
    private cms: CmsService,
    private gc: GiftCardService,
    private splitPay: SplitPaymentService,
    private tranCloud: TrancloudProviderService,
    private directusExpo: DirectusExpoProvider
  ) {
    this.store.dispatch(new GetBasket()).subscribe(() => this.store.dispatch(new ValidateBasket()));
    this.store.dispatch(new SetBanner('Checkout', true, true, ''));
    this.billingInfo = { ...this.store.selectSnapshot(state => state.basket.billingInfo) };
  }

  ngOnInit(): void {
    this.store.dispatch(new GetBillingSchemes());
    this.store
      .selectOnce(state => state.basket.capacityPlaceholder)
      .subscribe(cap => {
        if (!cap) {
          this.store.dispatch(new SetOrderPlaceholder()).subscribe(() => {
            this.store
              .selectOnce(state => state.basket.capacityPlaceholder)
              .subscribe((newCap: CapacityPlaceholder) => {
                this.startInterval(newCap.time);
              });
          });
        } else {
          this.startInterval(cap.time);
        }
      });
    this.basket$.subscribe(basket => {
      if (basket.coupon) {
        this.couponCode.setValue(basket.coupon.couponcode);
        this.couponCode.disable();
      } else {
        this.couponCode.reset();
        this.couponCode.enable();
      }
    });
  }

  ngOnDestroy() {
    clearInterval(this.intervalTimer);
  }

  submitCreditCard(): void {
    this.errorMessage = '';
    this.ccLoading = true;
    const modalRef = this.openSwipeCardModal(false);
    this.store.dispatch(new SetBillingInfo(this.billingInfo)).subscribe(() => {
      const state = this.store.selectSnapshot(state => state);
      this.store.dispatch(new SubmitTrancloudBasket()).subscribe(
        () => {
          this.ccLoading = false;
          this.modalService.dismissAll();
          this.store.dispatch(new ClearKioskUserData());
          this.router.navigateByUrl('/cks/confirmation');
          this.toastr.success('The order has been completed!');
          if (state.basket.basket.deliverymode === 'dinein') {
            this.modalService.open(DineInConfirmationModalComponent, {
              centered: true,
              backdrop: 'static',
              keyboard: false
            });
          }
        },
        error => {
          this.toastr.warning(typeof error === 'string' ? error : error.message);
          this.ccLoading = false;
          this.modalService.dismissAll();
          this.billingInfo = { ...this.store.selectSnapshot(state => state.basket.billingInfo) };
        }
      );
    });
  }

  submitGiftCard(): void {
    this.errorMessage = '';
    this.gcLoading = true;
    const trancloudSub = this.tranCloud.giftCardSale().subscribe(
      gc => {
        if (gc && gc.RStream && gc.RStream.CmdStatus === 'Success') {
          this.giftCardForm.patchValue({
            gcNumber: gc.RStream.PrePaidTrack2
          });
        } else {
          this.toastr.warning('Card could not be read, please enter Card Number');
        }
        modalRef.dismiss();
        this.openGiftCardInfoModal();
      },
      error => {
        this.modalService.dismissAll();
        this.toastr.error('Could not connect to Pin Pad. Please inform a cashier.');
      }
    );
    const modalRef = this.openSwipeCardModal(true);
    modalRef.componentInstance.manualClicked.subscribe(() => {
      this.modalService.dismissAll();
      this.openGiftCardInfoModal();
      trancloudSub.unsubscribe();
    });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  checkBalance(): void {
    if (!this.gcNumber.value || !this.gcPin.value) {
      this.toastr.warning('Gift Card Info Missing');
      return;
    }
    if (this.applyingGiftCard) {
      return;
    }
    this.applyingGiftCard = true;
    const gcDetails = {
      cardnumber: this.gcNumber.value,
      pin: this.gcPin.value
    };
    this.store.dispatch(new CheckBalance(gcDetails)).subscribe(() => {
      this.store
        .selectOnce(state => state.basket.giftCard)
        .subscribe(gc => {
          if (gc && gc[0] && gc[0].balance) {
            const balance = gc[0].balance;
            this.basketValidation$.pipe(take(1)).subscribe(val => {
              if (balance >= val.total) {
                this.billingInfo.billingmethod = 'storedvalue';
                this.store
                  .selectOnce(state => state.basket)
                  .subscribe((basket: BasketStateModel) => {
                    this.billingInfo.billingschemeid = basket.billingSchemes.find((s: any) => s.type === 'giftcard').id;
                    this.billingInfo.billingfields = [
                      {
                        name: 'number',
                        value: this.gcNumber.value
                      },
                      {
                        name: 'pin',
                        value: this.gcPin.value
                      }
                    ];
                    this.billingInfo.usertype = basket.billingInfo.usertype;
                    this.store.dispatch(new SetBillingInfo(this.billingInfo)).subscribe(() => {
                      this.store.dispatch(new SubmitBasket()).subscribe(
                        () => {
                          this.modalService.dismissAll();
                          this.applyingGiftCard = false;
                          this.gcLoading = false;
                          this.store.dispatch([new ClearKioskUserData(), new CleanGiftCard()]);
                          this.router.navigateByUrl('/cks/confirmation');
                          this.toastr.success('Order Complete!');
                          if (basket.basket.deliverymode === 'dinein') {
                            this.modalService.open(DineInConfirmationModalComponent, {
                              centered: true,
                              backdrop: 'static',
                              keyboard: false
                            });
                          }
                        },
                        error => {
                          this.toastr.warning(typeof error === 'string' ? error : error.message);
                          this.modalService.dismissAll();
                        }
                      );
                    });
                  });
              } else {
                this.applyingGiftCard = false;
                this.gcLoading = false;
                this.errorMessage =
                  'Gift Card must cover the entire purchase amount.\nPlease choose another payment method or contact the Call Center to place your order.';
                this.toastr.warning(
                  'This gift card does not cover the enter cost of the order. Please select a different payment.'
                );
                this.modalService.dismissAll();
                return;
              }
            });
          } else {
            this.toastr.warning('Gift Card not accepted.');
          }
        });
    });
  }

  applyCoupon(): void {
    this.couponLoading = true;
    this.store.dispatch(new ApplyCoupon(this.couponCode.value)).subscribe({
      next: () => {
        this.couponLoading = false;
      },
      error: error => {
        this.toastr.warning(error.error.message);
        this.couponLoading = false;
      }
    });
  }

  removeCoupon(): void {
    this.store.dispatch(new RemoveCoupon()).subscribe();
  }

  get gcNumber(): AbstractControl {
    return this.giftCardForm.get('gcNumber');
  }
  get gcPin(): AbstractControl {
    return this.giftCardForm.get('gcPin');
  }

  private openSwipeCardModal(isGiftCard: boolean): NgbModalRef {
    const modalRef = this.modalService.open(SwipeCardModalComponent, this.modalOptions);
    modalRef.componentInstance.isCustomerKiosk = true;
    modalRef.componentInstance.isGiftCard = isGiftCard;
    this.modalAccessibility();
    modalRef.componentInstance.xClicked.subscribe(() => this.modalService.dismissAll());
    modalRef.componentInstance.manualClicked.subscribe(() => {
      this.modalService.dismissAll();
      this.openGiftCardInfoModal();
    });
    return modalRef;
  }

  private openGiftCardInfoModal(): void {
    this.modalService.open(this.gcModal, this.modalOptions).result.then(() => {});
    this.modalAccessibility();
  }

  private startInterval(time: string) {
    this.intervalTimer = setInterval(() => {
      const diffTime = moment().diff(moment(time));
      const timeLimit = this.store.selectSnapshot((state: GlobalStateModel) => state.restaurant.settings)
        .checkout_timeout_length;
      const timeoutTime = timeLimit * 60000;
      if (diffTime > timeoutTime) {
        this.timeLeft = null;
        clearInterval(this.intervalTimer);
        this.openTimeoutModal();
      } else {
        const remainingTime = moment(timeoutTime).diff(moment(diffTime));
        const seconds =
          moment
            .duration(remainingTime)
            .seconds()
            .toString().length === 1
            ? '0' + moment.duration(remainingTime).seconds()
            : moment.duration(remainingTime).seconds();
        this.timeLeft = moment.duration(remainingTime).minutes() + ':' + seconds;
      }
      this.timeLimitLoading = false;
    }, 1000);
  }

  private openTimeoutModal() {
    const modalRef = this.modalService.open(TimeoutModalComponent, this.modalOptions);
    this.modalAccessibility();
    modalRef.componentInstance.continueClicked.subscribe(() => {
      this.modalService.dismissAll();
      this.router.navigate(['cks', 'order-summary']);
    });
  }

  private modalAccessibility() {
    setTimeout(() => {
      if (this.store.selectSnapshot(state => state.app.accessibilityMode)) {
        const modal = document.getElementsByClassName('modal-dialog-centered').item(0);
        modal.classList.add('d-none');
        modal.className = modal.className.replace('modal-dialog-centered', 'accessibility-transition');
        modal.classList.add('modal-dialog-end');
        modal.classList.remove('d-none');
      }
    });
  }
}
