import { Component, Input, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserStateModel } from '@app/store/state/user.state';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UpdatePassword, UpdateUser, UpdateUserPreferences } from '@app/store/actions/user.action';
import { Router } from '@angular/router';
import { User } from '../../models/olo.user';
import { ToastrService } from 'ngx-toastr';
import { UserCommunicationPreferences } from '../../models/olo.usercommunicationpreferences';
import { BooleanString } from '../../models/BooleanString';
import { GlobalStateModel } from '@app/store/state.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteAccountModalComponent } from '@app/components/delete-account-modal/delete-account-modal.component';

@Component({
  selector: 'lib-account-details',
  templateUrl: './account-details.component.html',
  styles: []
})
export class AccountDetailsComponent implements OnInit {
  @Input() buttons = true;
  @Input() basketCreated = true;
  @Input() orderText = 'order';
  @Input() dropdownChildren = ['individual', 'group', 'catering'];

  @Select() user$: Observable<UserStateModel>;

  passwordChangeCollapsed = true;
  editEmailCollapsed = true;
  changePasswordForm: UntypedFormGroup;
  editEmailForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store,
    private router: Router,
    private toastService: ToastrService,
    private modal: NgbModal
  ) {}

  ngOnInit() {
    this.changePasswordForm = this.resetPasswordForm();
    this.editEmailForm = this.resetEmailForm();
  }
  clickEvent(event: any) {}

  passwordConfirming(formGroup: AbstractControl): { invalid: boolean } {
    if (formGroup.get('password').value !== formGroup.get('confirm').value) {
      if (formGroup.get('password').touched && formGroup.get('password').touched) {
      }
      return { invalid: true };
    }
  }

  updatePassword() {
    // call to dispatch action to change password
    const currentPassword = this.changePasswordForm.get('currentPassword').value;
    const newPassword = this.changePasswordForm.get('newPasswords').get('password').value;
    this.store.dispatch(new UpdatePassword(currentPassword, newPassword)).subscribe(
      () => {
        // console.log( 'Password updated' );
        this.toastService.success('Password Updated');
      },
      (error: any) => {
        if (error.error && error.error.code === 200) {
          this.toastService.warning(error.error.message);
        } else if (error.error) {
          this.toastService.error(error.error.message);
        } else {
          this.toastService.error(error.message);
        }
      }
    );
    this.changePasswordForm = this.resetPasswordForm();
  }

  updateEmail() {
    const newEmail = this.editEmailForm.get('newEmail').value;
    const info: User = { ...this.store.selectSnapshot(state => state.user.info) };
    info.emailaddress = newEmail;
    info.firstname = this.editEmailForm.get('newFirstName').value;
    info.lastname = this.editEmailForm.get('newLastName').value;
    this.store
      .dispatch([new UpdateUser(info), new UpdateUserPreferences(this.editEmailForm.get('newEmailOptIn').value)])
      .subscribe(
        (res: any) => {
          // Next
          this.toastService.success('Account Info Updated');
          this.editEmailCollapsed = true;
        },
        (error: any) => {
          if (error.error && error.error.code === 200) {
            this.toastService.warning(error.error.message);
          } else if (error.error) {
            this.toastService.error(error.error.message);
          } else {
            this.toastService.error(error.message);
          }
        }
      );
    this.editEmailForm = this.resetEmailForm();
  }

  resetPasswordForm(): UntypedFormGroup {
    return this.fb.group({
      currentPassword: ['', [Validators.required]],
      newPasswords: this.fb.group(
        {
          password: ['', Validators.required],
          confirm: ['', Validators.required]
        },
        { validator: this.passwordConfirming }
      )
    });
  }

  resetEmailForm(): UntypedFormGroup {
    const info: User = { ...this.store.selectSnapshot((state: GlobalStateModel) => state.user.info) };
    const preferences: UserCommunicationPreferences = {
      ...this.store.selectSnapshot((state: GlobalStateModel) => state.user.userPreferences)
    };
    return this.fb.group({
      newEmail: [info.emailaddress, [Validators.required, Validators.email]],
      newFirstName: [info.firstname, [Validators.required]],
      newLastName: [info.lastname, [Validators.required]],
      newEmailOptIn: [preferences.optin === BooleanString.True, [Validators.required]]
    });
  }

  openDeleteAccountModal() {
    this.modal.open(DeleteAccountModalComponent, {
      centered: true,
      keyboard: false
    });
  }
}
