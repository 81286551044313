import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { RestaurantStateModel } from '@app/store/state/restaurant.state';
import { AppStateModel } from '@app/store/state/app.state';
import { Observable, Subscription } from 'rxjs';
import { Product } from 'src/assets/chepri-modules/src/models/olo.product';
import { GetProduct, ProdImg } from '@app/store/actions/product.action';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {
  AddProductToBasket,
  AddSavedProductToBasket,
  CreateBasket,
  EmptyPreviousBasket,
  GetTimeSlots
} from '@app/store/actions/basket.action';
import { ProductService } from '@app/providers/product.service';
import { ToastrService } from 'ngx-toastr';
import { Limiter, Limiters } from 'src/assets/chepri-modules/src/models/Limiter';
import { SetBanner } from '@app/store/actions/app.action';
import { CleanCalendars, GetMenu, GetCalendars, GetRestaurantCapacity } from '@app/store/actions/restaurant.action';
import { CmsService } from '@app/providers/cms.service';
import { BasketState } from '@app/store/state/basket.state';
import { SoldoutModalComponent } from 'src/assets/chepri-modules/src/lib/soldout-modal/soldout-modal.component';
import { CmsMessages, Message } from '@app/models/cms.messages';
import moment from 'moment';
import { ModeService } from '@app/providers/mode.service';
import { Basket } from '../../../assets/chepri-modules/src/models/olo.basket';
import { take } from 'rxjs/operators';
import { ScheduledMessaging } from '@app/models/directus.collections';
import { ResponseRetrieveSavedProductsSavedProduct } from '@app/models/saved-products.olo';
import { GlobalStateModel } from '@app/store/state.model';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @Select() restaurant$: Observable<RestaurantStateModel>;
  @Select() app$: Observable<AppStateModel>;
  @Select((state: any) => state.basket.basket) basket$: Observable<Basket>;
  @ViewChild('message') messageModal: ElementRef;

  mode: string;

  sub: any;
  closeResult: string;
  selectedProduct: Product;
  locationId: string;
  selectedProdID = 0;
  messages: ScheduledMessaging[];
  activeMessage: ScheduledMessaging;
  selectedCat = 0;

  private subs: Subscription[] = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private modalService: NgbModal,
    private productService: ProductService,
    private toast: ToastrService,
    private cms: CmsService,
    private modeService: ModeService
  ) {
    this.mode = this.modeService.getStoredMode();

    if (this.mode === 'kms') {
      this.store.dispatch(new SetBanner('START OVER', true, true, ''));
    } else {
      this.store.dispatch(new SetBanner('MENU', true, true, ''));
    }
  }

  ngOnInit() {
    this.subs.push(
      this.route.params.subscribe(params => {
        const state = this.store.selectSnapshot(state => state);
        if (params.id) {
          this.store.dispatch(new GetMenu(this.locationId)).subscribe(res => {
            this.store.dispatch(new GetCalendars(this.locationId, false)).subscribe(res => {});
            this.store.dispatch(new GetRestaurantCapacity(this.locationId));
            this.basket$.pipe(take(1)).subscribe(basket => {
              if (!basket || basket.vendorid.toString() !== this.locationId) {
                // Check if basket exists
                this.store.dispatch(new CreateBasket(this.locationId));
              }
            });
          });
        }
      })
    );
    this.store.dispatch(new CleanCalendars());
    this.store.dispatch(new EmptyPreviousBasket());
    this.doScheduledMessage();
  }

  navigateToProduct(product: Product) {
    if (this.mode === 'kms') {
      this.router.navigateByUrl('/kiosk/product/' + product.id);
    } else if (this.mode === 'cks') {
      this.router.navigateByUrl('/cks/product/' + product.id);
    } else {
      this.router.navigateByUrl('/product/' + product.id);
    }
  }

  addProductToCart(product: Product, maxOrder?: TemplateRef<any>) {
    this.selectedProdID = product.id;
    // Check if max is reached for product category
    // alert(this.store.selectSnapshot(state => state.basket.capacity.items));
    const state = this.store.selectSnapshot((state: GlobalStateModel) => state.basket);
    const capacity = state.capacity._items;
    const limiters = new Limiters(capacity);
    if (maxOrder && product.metadata && limiters && limiters.hasCategory(Number(product.metadata[0].value))) {
      const limiterState = limiters.items[limiters.find(Number(product.metadata[0].value))];
      const limiter = new Limiter(limiterState.categoryId, limiterState.max, limiterState.products);
      if (limiter.isFull() || !limiter.canAdd()) {
        // this.toast.warning('Cannot add more than ' + limiter.max + ' items of this type');
        this.maxOrderLimit(maxOrder);
        this.selectedProdID = 0;
      } else {
        this.store
          .dispatch(
            new AddProductToBasket(
              this.productService.convertProductToBasketProductToAdd(product, this.productService.getSelected(), 1),
              product.metadata
            )
          )
          .subscribe(
            data => {
              this.selectedProdID = 0;
            },
            error => {
              this.selectedProdID = 0;
            }
          );
      }
    } else if (product.metadata && limiters) {
      // Quick adding, so only adding quantity = 1
      this.store
        .dispatch(
          new AddProductToBasket(
            this.productService.convertProductToBasketProductToAdd(product, this.productService.getSelected(), 1),
            product.metadata
          )
        )
        .subscribe(
          data => {
            this.selectedProdID = 0;
          },
          error => {
            this.selectedProdID = 0;
          }
        );
    } else {
      // No metadata
      this.store
        .dispatch(
          new AddProductToBasket(
            this.productService.convertProductToBasketProductToAdd(product, this.productService.getSelected(), 1)
          )
        )
        .subscribe(
          data => {
            this.selectedProdID = 0;
          },
          error => {
            this.selectedProdID = 0;
          }
        );
    }
  }

  addSavedProductToCart(savedProduct: ResponseRetrieveSavedProductsSavedProduct, maxOrder?: any) {
    const product = this.productService.getProductFromChainProductID(savedProduct.product.chainproductid);
    this.selectedProdID = product.id;
    // Check if max is reached for product category
    // alert(this.store.selectSnapshot(state => state.basket.capacity.items));
    const state = this.store.selectSnapshot((state: GlobalStateModel) => state.basket);
    const capacity = state.capacity._items;
    const limiters = new Limiters(capacity);
    if (maxOrder && product.metadata && limiters && limiters.hasCategory(Number(product.metadata[0].value))) {
      const limiterState = limiters.items[limiters.find(Number(product.metadata[0].value))];
      const limiter = new Limiter(limiterState.categoryId, limiterState.max, limiterState.products);
      if (limiter.isFull() || !limiter.canAdd()) {
        // this.toast.warning('Cannot add more than ' + limiter.max + ' items of this type');
        this.maxOrderLimit(maxOrder);
        this.selectedProdID = 0;
      } else {
        this.store
          .dispatch(new AddSavedProductToBasket(savedProduct, product.id, product.metadata ? product.metadata : []))
          .subscribe(
            data => {
              this.selectedProdID = 0;
            },
            error => {
              this.selectedProdID = 0;
            }
          );
      }
    } else if (product.metadata && limiters) {
      // Quick adding, so only adding quantity = 1
      this.store
        .dispatch(new AddSavedProductToBasket(savedProduct, product.id, product.metadata ? product.metadata : []))
        .subscribe(
          data => {
            this.selectedProdID = 0;
          },
          error => {
            this.selectedProdID = 0;
          }
        );
    } else {
      // No metadata
      this.store
        .dispatch(new AddSavedProductToBasket(savedProduct, product.id, product.metadata ? product.metadata : []))
        .subscribe(
          data => {
            this.selectedProdID = 0;
          },
          error => {
            this.selectedProdID = 0;
          }
        );
    }
  }

  saveAndAddProductToCart() {
    // use selectedProduct to save the product to favorites
    // this.addProductToCart();
  }

  maxOrderLimit(maxOrder: any) {
    this.modalService.open(maxOrder, { ariaLabelledBy: 'maxOrder', centered: true }).result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getOrderNameDismissReason(reason)}`;
      }
    );
  }
  private getOrderNameDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      // this.addProductToCart();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      // this.addProductToCart();
      return 'by clicking on a backdrop';
    } else {
      // this.addProductToCart();
      return `with: ${reason}`;
    }
  }

  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'informationModal', centered: true }).result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed`;
      }
    );
  }

  doScheduledMessage() {
    this.cms.getScheduledMessages().subscribe(res => {
      this.messages = res;
      const messageArray: ScheduledMessaging[] = [];
      this.messages.forEach(message => {
        if (message.status === 'published') {
          if (Number(message.where) === 2 || Number(message.where) === 3) {
            // Shown on Menu or Both
            const today = moment();
            // console.log(today);
            // console.log(moment(message.start_date));
            switch (Number(message.schedule_type)) {
              case 1: // Recurring Message
                message.days_shown.forEach(day => {
                  if (today.day() === Number(day)) {
                    messageArray.push(message);
                  }
                });
                break;
              case 2: // Single Day
                if (moment(today).isSame(moment(message.date_shown, 'YYYY-MM-DD'), 'day')) {
                  messageArray.push(message);
                }
                break;
              case 3: // Time Period
                if (
                  moment(today).isSameOrAfter(moment(message.start_date, 'YYYY-MM-DD')) &&
                  moment(today).isSameOrBefore(moment(message.end_date, 'YYYY-MM-DD'))
                ) {
                  messageArray.push(message);
                }
                break;
            }
            if (messageArray.length > 0) {
              this.activeMessage = messageArray[0];
              this.modalService.open(this.messageModal, {
                centered: true,
                keyboard: false,
                backdrop: 'static'
              });
            }
          }
        }
      });
    });
  }
}
