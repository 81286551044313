import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppStateModel } from '@app/store/state/app.state';
import { Basket } from 'src/assets/chepri-modules/src/models/olo.basket';
import { EmptyBasket, EmptyPreviousBasket, ApplyGiftCard } from '@app/store/actions/basket.action';
import { orderstatus } from 'src/assets/chepri-modules/src/models/olo.recentorders';
import { BasketState, BasketStateModel } from '@app/store/state/basket.state';
import { GlobalStateModel } from '@app/store/state.model';
import { Order } from '@lib/models/olo.order';

@Component({
  selector: 'app-checkout-bag',
  templateUrl: './checkout-bag.component.html',
  styleUrls: ['./checkout-bag.component.scss']
})
export class CheckoutBagComponent implements OnInit {
  @Select() app$: Observable<AppStateModel>;
  @Select() basket$: Observable<BasketStateModel>;

  @Input() basket: Basket;
  @Input() isPayment: boolean;
  @Input() isConfirmation: boolean;
  @Input() isOrderDetails = true;
  @Input() buttonAvailable = false;
  @Input() isLoading = false;
  @Input() paymentType: string = '';

  @Output() nextPageEvent = new EventEmitter<string>();
  @Output() couponCodeSubmitEvent = new EventEmitter<UntypedFormGroup>();

  couponPromoForm: UntypedFormGroup;
  previousBasket: Order;
  isSelected: boolean;
  onTimeChange: any;
  giftCardBalance = 0;

  constructor(private fb: UntypedFormBuilder, private router: Router, private store: Store) {}

  ngOnInit() {
    this.couponPromoForm = this.fb.group({
      couponCode: ['']
    });

    this.app$.subscribe(appstate => {
      // this.hasOrderDetails = appstate.hasOrderDetails;
      // this.hasPaymentDetails = appstate.hasPaymentDetails;
    });
    const past = this.store.selectSnapshot((state: GlobalStateModel) => state.basket);
    this.previousBasket = past.previousBasket;
    this.basket$.subscribe(basket => {
      let giftbalance = 0;
      if (basket && basket.giftCard) {
        basket.giftCard.forEach((card: any) => {
          giftbalance = giftbalance + card.balance;
        });
        this.giftCardBalance = giftbalance;
      }
    });
  }

  submitCreditCardEvent(form?: UntypedFormGroup) {
    this.couponCodeSubmitEvent.emit(this.couponPromoForm);
  }

  continueToNextPage() {
    this.nextPageEvent.emit('continue');
  }

  viewMenu() {
    this.router.navigateByUrl('/menu');
  }
  newOrder() {
    this.store.dispatch(new EmptyPreviousBasket());
    this.router.navigateByUrl('/menu');
  }
  removeGiftCard() {
    this.store.dispatch(new ApplyGiftCard(false));
  }

  continueBtn() {
    this.isSelected = this.onTimeChange.includes('Select Time');
  }
}
