import { Component, OnDestroy } from '@angular/core';
import { ModeService } from '@app/providers/mode.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { EmptyPreviousBasket, CreateBasket, GetTimeSlots, SetHandoffMode } from '@app/store/actions/basket.action';
import { Select, Store } from '@ngxs/store';
import { SoldoutModalComponent } from 'src/assets/chepri-modules/src/lib/soldout-modal/soldout-modal.component';
import { GetMenu, GetRestaurantCapacity, GetCalendars } from '@app/store/actions/restaurant.action';
import { DirectusCurrentOrder } from '@app/models/capacity.model';
import { interval, Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CapacityService } from '@app/providers/capacity.service';
import moment from 'moment';
import { RestaurantState } from '@app/store/state/restaurant.state';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerKioskService } from '@app/providers/customer-kiosk.service';
import { ToggleAccessibility } from '@app/store/actions/app.action';
import { TrancloudProviderService } from '@app/datacap-trancloud/services/trancloud-provider.service';
import { ToastrService } from 'ngx-toastr';
import { DineInConfirmationModalComponent } from '@app/components/dine-in-confirmation-modal/dine-in-confirmation-modal.component';
import { CustomerKiosk, DirectusUsers, Locations } from '@app/providers/expo/directus/directus-collections.interface';
import { GlobalStateModel } from '@app/store/state.model';
import later from '@breejs/later';

@Component({
  selector: 'app-cks-home',
  templateUrl: './home.component.html',
  styleUrls: ['../customer-kiosk.component.scss']
})
export class HomeComponent implements OnDestroy {
  @Select((state: any) => state.app.accessibilityMode) accessibilityMode$: Observable<boolean>;

  kiosk: any;
  isLoading: string;
  subscriptions: Subscription[] = [];
  isKioskOn = true;
  isDineinOn = true;
  isTakeoutOn = true;
  isDineInLoading = false;
  isTakeoutLoading = false;

  private interval: any;

  constructor(
    private router: Router,
    private modeService: ModeService,
    private modalService: NgbModal,
    private store: Store,
    private capacity: CapacityService,
    private route: ActivatedRoute,
    private kioskService: CustomerKioskService,
    private tranCloud: TrancloudProviderService,
    private toastr: ToastrService
  ) {
    this.kiosk = {
      restaurantID: '113670',
      locationRestaurantId: 5,
      locationName: null,
      upcomingSlots: []
    };
    this.accessibilityMode$.pipe(take(1)).subscribe(a => {
      if (a) {
        this.store.dispatch(new ToggleAccessibility());
      }
    });
    this.modeService.setApplicationMode('cks');
    const token = this.kioskService.getStoredToken();
    if (token) {
      this.setPageSettings(token);
    }
  }

  setPageSettings(token: string) {
    this.kioskService.getKioskInfo(token).subscribe(kiosk => {
      const kioskInfo = kiosk;
      this.isKioskOn = kioskInfo.kiosk_available;
      this.isDineinOn = !(kioskInfo.location as Locations).disable_dinein;
      this.isTakeoutOn = !(kioskInfo.location as Locations).disable_takeout;
      this.kiosk.restaurantID = String((kioskInfo.location as Locations).olo_id);
      this.subscriptions.push(interval(5000).subscribe(() => this.setupCapacityData(this.kiosk.restaurantID)));
      this.kioskService.getLocationTrancloudInfo((kioskInfo.location as Locations).id).subscribe(locInfo => {
        this.runSync(kioskInfo, locInfo);
      });
      this.interval = later.setInterval(
        () => this.setupCapacityData(this.kiosk.restaurantID),
        later.parse.cron('* * * * *')
      );
    });
  }

  setupCapacityData(kioskLocation: string) {
    this.store.dispatch(new GetMenu(String(kioskLocation))).subscribe(() => {
      const res = this.store.selectSnapshot(state => state);
      this.store.dispatch(new GetRestaurantCapacity(res.restaurant.restaurant.id)).subscribe(() => {
        this.store.dispatch(new GetCalendars(res.restaurant.restaurant.id, false)).subscribe(() => {
          const calres = this.store.selectSnapshot(state => state);
          this.setCapacityBuckets(calres, res.restaurant.restaurant.id);
        });
      });
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    if (this.interval) {
      this.interval.clear();
    }
  }

  runSync(kioskInfo: CustomerKiosk, locInfo: Partial<DirectusUsers>) {
    this.tranCloud.tranDeviceID = locInfo.location_trancloud_id;
    this.tranCloud.merchantID = locInfo.location_merchant_id;
    this.tranCloud
      .checkConnection(
        kioskInfo.mac_ip_address,
        kioskInfo.pin_pad_type === 'lane3000' ? 'EMV_LANE3000' : 'EMV_LINK2500',
        kioskInfo.pin_pad_provider === 'datacap' ? 'DATACAP_E2E' : 'HEARTLAND',
        kioskInfo.use_mac_address ? 'MAC' : 'IP'
      )
      .subscribe(
        () => {},
        error => {
          this.toastr.warning(typeof error === 'string' ? error : error.message);
        }
      );
  }

  setCapacityBuckets(state: any, id: string) {
    // Get Locations orders
    this.subscriptions.push(
      this.capacity.getOrders(id, false).subscribe((orders: DirectusCurrentOrder[]) => {
        const timeSlots = this.capacity.getNextAvailableTimes(
          state.restaurant.capacity_settings,
          state.restaurant.calendar.calendar[0].ranges[0].end,
          orders,
          false
        );
        const restaurant = this.store.selectSnapshot((state: GlobalStateModel) => state.restaurant.restaurant);
        const waitTimes: string[] = [];
        timeSlots.forEach(slot => {
          if (slot) {
            const currentTime = moment();
            const keyTime = moment(slot.key, 'h:mm A').utcOffset(restaurant.utcoffset, true);
            const difference = moment(keyTime).diff(moment(currentTime.startOf('minute')), 'minutes');
            waitTimes.push(difference.toString());
          }
        });
        this.kiosk.upcomingSlots = waitTimes;
      })
    );
  }

  startOrder(orderType: string, restaurantID: string) {
    this.store.dispatch(new EmptyPreviousBasket());
    if (orderType === 'pickup') {
      this.isTakeoutLoading = true;
      this.store.dispatch(new CreateBasket(restaurantID.toString())).subscribe(() => {
        const res = this.store.selectSnapshot(state => state);
        this.store.dispatch(new GetCalendars(res.restaurant.restaurant.id, false)).subscribe(res => {
          this.store.dispatch(new GetTimeSlots(true)).subscribe(() => {
            const slotRes = this.store.selectSnapshot(state => state);
            this.isTakeoutLoading = false;
            if (slotRes.basket.timeSlots.length === 0) {
              this.openSoldOutModal();
            } else {
              this.router.navigateByUrl(`/cks/menu/${restaurantID}`);
              this.modalService.dismissAll();
            }
          });
        });
      });
    } else if (orderType === 'dinein') {
      this.isDineInLoading = true;
      const modalRef = this.modalService.open(DineInConfirmationModalComponent, {
        centered: true,
        backdrop: 'static',
        keyboard: false
      });
      setTimeout(() => {
        if (this.store.selectSnapshot(state => state.app.accessibilityMode)) {
          const modal = document.getElementsByClassName('modal-dialog-centered').item(0);
          modal.classList.add('d-none');
          modal.className = modal.className.replace('modal-dialog-centered', 'accessibility-transition');
          modal.classList.add('modal-dialog-end');
          modal.classList.remove('d-none');
        }
      });
      modalRef.componentInstance.xClicked.subscribe(() => {
        modalRef.close();
        this.isDineInLoading = false;
      });
      modalRef.componentInstance.confirmClicked.subscribe(() => {
        modalRef.close();
        this.store.dispatch(new CreateBasket(restaurantID.toString())).subscribe(() => {
          const res = this.store.selectSnapshot(state => state);
          this.store.dispatch(new SetHandoffMode('dinein'));
          this.store.dispatch(new GetCalendars(res.restaurant.restaurant.id, false)).subscribe(_ => {
            this.store.dispatch(new GetTimeSlots(true)).subscribe(() => {
              const slotRes = this.store.selectSnapshot(state => state);
              this.isDineInLoading = false;
              if (slotRes.basket.timeSlots.length === 0) {
                this.openSoldOutModal();
              } else {
                this.router.navigateByUrl(`/cks/menu/${restaurantID}`);
                this.modalService.dismissAll();
              }
            });
          });
        });
      });
    }
  }

  goToMenu() {
    this.router.navigateByUrl(`/cks/menu`);
  }

  openSoldOutModal() {
    let modalRef;
    const modalOptions: NgbModalOptions = { centered: true, size: 'lg', windowClass: 'hideScroll' };
    modalRef = this.modalService.open(SoldoutModalComponent, modalOptions);
    modalRef.componentInstance.xClicked.subscribe(() => {
      this.modalService.dismissAll();
    });
    modalRef.componentInstance.continueClicked.subscribe(() => {
      this.router.navigateByUrl('/menu');
      this.modalService.dismissAll();
    });
  }
}
