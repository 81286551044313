import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Product } from '../../models/olo.product';
import { CmsService } from '@app/providers/cms.service';
import { Store } from '@ngxs/store';
import { RestaurantState } from '@app/store/state/restaurant.state';
import { ProductService } from '../../../../../app/providers/product.service';
import { environment } from '../../../../../environments/environment';
import { GlobalStateModel } from '@app/store/state.model';

@Component({
  selector: 'lib-kiosk-card',
  templateUrl: './kiosk-card.component.html',
  styles: []
})
export class KioskCardComponent implements OnInit {
  @Input() product: Product;
  @Input() text: string;
  @Input() defaultImageUrl: string;
  @Input() includesBafala: boolean;
  @Input() imagePath: string;
  @Output() addToBag = new EventEmitter<any>();
  @Output() customize = new EventEmitter<any>();
  @Input() isLoading: boolean;

  doneLoading = false;
  hasModifiers = false;
  loadingType = '';

  constructor(private cms: CmsService, private store: Store, private productService: ProductService) {}

  ngOnInit() {
    // If ingredients include Bafala
    // TODO: Needs updated to check actual ingredients - might require getting modifiers/ingredients?
    if (this.product.description && this.product.description.toLowerCase().includes('bufala')) {
      this.includesBafala = true;
    }
    this.setImage();
    this.productService.getProductOptions(this.product.id).subscribe(res => {
      if (res.optiongroups.length > 0) {
        this.hasModifiers = true;
      }
      this.doneLoading = true;
    });
  }

  setImage() {
    const images = this.store.selectSnapshot((state: GlobalStateModel) => state.restaurant.images);
    const imagePath = images.data.find((obj: { name: string }) => obj.name === this.product.name);
    if (imagePath && imagePath.image) {
      this.imagePath = environment.cmsDomain.concat('assets/', imagePath.image.id);
    } else {
      this.imagePath = this.defaultImageUrl;
    }
  }

  emitAddToBag(index?: number) {
    this.loadingType = 'quickadd';
    if (!this.doneLoading) {
      return;
    }
    this.addToBag.emit(index);
  }

  emitCustomize(index?: number) {
    this.loadingType = 'customize';
    if (!this.doneLoading) {
      return;
    }
    this.customize.emit(index);
  }
}
