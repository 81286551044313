import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { RestaurantService } from '@app/providers/restaurant.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'intervalToMinutesRemaining',
  pure: false
})
export class IntervalToMinutesRemainingPipe implements PipeTransform {
  constructor(private restaurantService: RestaurantService) {}

  transform(interval: string, restaurantID: string): Observable<string> {
    return this.restaurantService.getRestaurant(Number(restaurantID)).pipe(
      map(restaurant => {
        const total_minutes = moment(interval, 'h:mm A')
          .utcOffset(restaurant.utcoffset, true)
          .diff(moment().startOf('minute'), 'minutes');
        const minutes = total_minutes % 60;
        const hours = (total_minutes - minutes) / 60;
        if (hours > 0) {
          return hours + ' hr ' + minutes + ' min';
        }
        return minutes + ' min';
      })
    );
  }
}
