import { State, Action, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { GetProduct, ProdImg } from '../actions/product.action';
import { Product } from 'src/assets/chepri-modules/src/models/olo.product';
import { ProductService } from '@app/providers/product.service';
import { Modifiers } from 'src/assets/chepri-modules/src/models/olo.modifiers';
import { RestaurantState } from './restaurant.state';
import { Category } from 'src/assets/chepri-modules/src/models/olo.category';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { GlobalStateModel } from '@app/store/state.model';

export interface ProductStateModel {
  product: Product;
  modifiers: Modifiers;
  url: any;
}

@State<ProductStateModel>({
  name: 'product',
  defaults: {
    product: null,
    modifiers: null,
    url: null
  }
})
@Injectable({
  providedIn: 'root'
})
export class ProductState {
  constructor(private productService: ProductService, private store: Store) {}

  @Action(GetProduct)
  getMenu({ patchState }: StateContext<ProductStateModel>, { productId }: GetProduct) {
    const categories: Category[] = this.store.selectSnapshot((state: GlobalStateModel) => state.restaurant.menu)
      .categories;
    let product: Product;
    if (categories.length > 0) {
      categories.forEach((cat: Category) => {
        cat.products.forEach(prod => {
          if (prod.id === productId) {
            product = prod;
          }
        });
      });
    }

    this.store.dispatch(new ProdImg(product));
    return this.productService.getProductModifiers(productId).pipe(
      tap(res => {
        const modifiers = res;
        patchState({ product, modifiers });
        // dispatch(new GetCart());
      })
    );
  }

  @Action(ProdImg)
  prodImg({ patchState, getState }: StateContext<ProductStateModel>, payload: any) {
    let path = '';
    const images = this.store.selectSnapshot((state: GlobalStateModel) => state.restaurant.images).data;
    const imagePath = images.find((obj: { name: string }) => obj.name === payload.payload.name);
    if (imagePath.image) {
      path = environment.cmsDomain.concat('assets/', imagePath.image.id);
    } else {
      path = '';
    }
    patchState({ url: path });
  }
}
