import { CustomField } from './olo.customfield';

export interface recentorders {
  orders: orderstatus[]; // List of recent orders for the user
}

export interface orderstatus {
  id: string; // Olo Order guid
  oloid: string; // Internal Olo Order ID. When reaching out to Olo about an order, please provide this id
  vendorid: number; // Restaurant ID
  status: string; // Status of the order. Possible values are "Completed", "Canceled", "Transmitting", "Scheduled", "Pending Manual Fire", and "In Progress".
  subtotal: number; // Order subtotal
  salestax: number; // Total sales tax for the order
  taxes: taxresult[]; // List of taxes for the order
  fees: orderfee[]; // List of fees for the order
  totalfees: number; // Total fees for the order
  total: number; // Total (including tax, tip, etc.)
  timemode: string; // The time mode of the basket. Possible values are "manualfire", "advance", or "asap"
  mode: string; // Legacy value. Currently hardcoded to always be 'orderahead'.
  billingaccountid?: string; // Billing account id used to pay for the order. Only returned for orders paid with a single billing account where the account was saved on file
  billingaccountids?: string[]; // List of billing account ids used to pay for the order. Only returned if multiple payment types were used.
  deliveryaddress?: DeliveryAddress; // Delivery address for the order. Only populated for orders with a deliverymode (a.k.a. handoff mode) of delivery or dispatch
  customfields: CustomField[];
  posreferenceresponse: string;
  iseditable: boolean;
  discount: number;
  discounts: discount[];
  orderref: string;
  timeplaced: string;
  readytime: string;
  vendorname: string;
  vendorextref: string;
  deliverymode: string;
  customerhandoffcharge?: number;
  arrivalstatus: string;
  hasolopass: boolean;
  products: orderstatusproduct[];
  unavailableproducts?: orderstatusproduct[];
}

export interface taxresult {
  label: string;
  tax: number;
}

export interface orderfee {
  amount: number;
  description: string;
}

export interface DeliveryAddress {
  id?: number;
  building?: string;
  streetaddress: string;
  city: string;
  zipcode: string;
  phonenumber: string;
  specialinstructions?: string;
  isdefault: boolean;
}

export interface discount {
  description: string;
  amount: number;
}

export interface orderstatusproduct {
  id: string;
  chainproductid: string;
  name: string;
  quantity: number;
  totalcost: number;
  specialinstructions: string;
  custompassthroughdata: string;
  choices: orderstatusproductchoice[];
}

export interface orderstatusproductchoice {
  id: number;
  chainchoiceid: number;
  name: string;
  quantity: string;
}
