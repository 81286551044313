import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { Restaurant } from '../../models/olo.restaurant';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store, Select } from '@ngxs/store';
import { RestaurantState } from '@app/store/state/restaurant.state';
import { UpdateDistance, AddCalendars, CleanCalendars } from '@app/store/actions/restaurant.action';
import { UserState, UserStateModel } from '@app/store/state/user.state';
import { GetRecentOrders } from '@app/store/actions/user.action';
import { Observable } from 'rxjs';
import { FormatService } from '../../providers/format.service';
import { CmsService } from '@app/providers/cms.service';
import { environment } from '@env/environment';
import { MobileService } from '@app/providers/mobile.service';
import { Result } from '@mapbox/mapbox-gl-geocoder';
import { Locations } from '@app/models/directus.collections';
import { GlobalStateModel } from '@app/store/state.model';

declare var google: any;

interface OrderHeader {
  restaurantID: number;
  orderType: string;
}

@Component({
  selector: 'lib-location-list',
  templateUrl: './location-list.component.html',
  styles: []
})
export class LocationListComponent implements OnInit {
  @Input() locations: any;
  @Input() selectedLocation: number;
  @Input() cmsLocations: Locations[] = [];
  @Input() geoLocLoading: boolean;
  closeResult: string;
  @Output() locationChange = new EventEmitter<any>();
  @Output() proceedToMenuEvent = new EventEmitter<OrderHeader>();
  @Output() locationClickEvent = new EventEmitter<Restaurant>();
  @Output() checkDelivery = new EventEmitter<any>();
  @Output() setCurrentLoc = new EventEmitter<any>();
  @Select() user$: Observable<UserStateModel>;
  @ViewChild('searchBarComponent', { static: true }) searchBarComponent: SearchBarComponent;
  locChange: boolean;
  deliveryLocation: any;
  distances: any;
  pastOrderName: any;
  pastLocation: any;
  locStoreTimes: any;
  environment: any;
  mode: 'pickup' | 'dispatch' | 'dinein' = 'pickup';
  constructor(
    private modalService: NgbModal,
    private store: Store,
    private format: FormatService,
    private cms: CmsService,
    public mobile: MobileService
  ) {
    this.distances = [];
    this.locStoreTimes = [];
  }

  ngOnInit() {
    this.store.dispatch(new CleanCalendars());
    this.getStoreTimes();
    this.environment = environment.production;
    this.store.dispatch(new GetRecentOrders()).subscribe(() => {
      const user = this.store.selectSnapshot((state: GlobalStateModel) => state.user);
      if (user.recentOrders && user.recentOrders.orders.length > 0) {
        this.pastOrderName = user.recentOrders.orders[0].vendorname;
        for (const loc of this.locations) {
          if (loc.name === this.pastOrderName) {
            this.pastLocation = loc;
          }
        }
      }
    });
    this.mobile.mobileCheck();
  }

  getStoreTimes() {
    for (const location of this.locations) {
      const name = location.name;
      this.store.dispatch(new AddCalendars(location)).subscribe(() => {
        this.locStoreTimes = this.store.selectSnapshot((state: GlobalStateModel) => state.restaurant).calendars;
        // console.log('this locStoreTimes', this.locStoreTimes);
      });
    }
  }

  searchChange(result: Result) {
    this.locationChange.emit(result);
  }

  setCurrentLocation() {
    this.setCurrentLoc.emit();
  }

  // Passes the Restaurant ID and Order Type to the parent component
  passOrderHeader = (identifier: number, type: string) => {
    const temp = {
      restaurantID: identifier,
      orderType: type
    };
    this.proceedToMenuEvent.emit(temp);
  };

  // // Passes the location that was clicked back to the parent component
  // passLocation(location: Restaurant) {
  //   this.locationClickEvent.emit(location);
  // }

  deliveryZone(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'informationModal', centered: true, windowClass: 'custom-pop' });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
