import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { RestaurantStateModel } from '@app/store/state/restaurant.state';
import { firstValueFrom, Observable } from 'rxjs';
import {
  GetCalendars,
  GetCMSLocations,
  GetMenu,
  GetRestaurantCapacity,
  GetRestaurants,
} from '@app/store/actions/restaurant.action';
import {
  CreateBasket,
  EmptyPreviousBasket,
  GetTimeSlots,
  SetDispatchAddress,
  SetHandoffMode,
} from '@app/store/actions/basket.action';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SetBanner } from '@app/store/actions/app.action';
import { Restaurant } from 'src/assets/chepri-modules/src/models/olo.restaurant';
import { map, tap } from 'rxjs/operators';
import { SoldoutModalComponent } from 'src/assets/chepri-modules/src/lib/soldout-modal/soldout-modal.component';
import { CmsMessages, Message } from '@app/models/cms.messages';
import { CmsService } from '@app/providers/cms.service';
import moment from 'moment';
import { Locations, ScheduledMessaging } from '@app/models/directus.collections';
import { SplashScreen } from '@capacitor/splash-screen';
import { Capacitor } from '@capacitor/core';
import { head } from 'lodash';
import { GlobalStateModel } from '@app/store/state.model';
import { environment } from '@env/environment';

interface OrderHeader {
  restaurantID: number;
  orderType: string;
}

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
})
export class LocationsComponent implements OnInit {
  @Select() restaurant$: Observable<RestaurantStateModel>;
  // @ts-ignore
  @Select((state) => state.restaurant.cmsLocations) cmsLocations$: Observable<Locations[]>;
  @ViewChild('message') messageModal: ElementRef;
  @ViewChild('transferModal') transferModal: ElementRef;
  closeResult: string;
  restaurants: Observable<Restaurant[]>;
  messages: ScheduledMessaging[];
  activeMessage: ScheduledMessaging;
  selectedLocation = 0;
  orderHeader: OrderHeader;

  constructor(
    private router: Router,
    private store: Store,
    private modalService: NgbModal,
    private cms: CmsService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    // TODO: Include Private is set to true for testing - should probably be changed before launch
    this.restaurants = this.store.dispatch(new GetRestaurants(!environment.production)).pipe(
      map((res) => this.store.selectSnapshot((state: GlobalStateModel) => state.restaurant.restaurants)),
      tap(() => {
        if (Capacitor.getPlatform() !== 'web') {
          SplashScreen.hide({ fadeOutDuration: 500 });
        }
      }),
    );
    this.store.dispatch(new SetBanner('Find Your Location', false, false, ''));
    this.store.dispatch(new GetCMSLocations());
    this.doScheduledMessage();
  }

  goToMenu(header: OrderHeader) {
    const order = this.store.selectSnapshot((state: GlobalStateModel) => state.basket.basket);
    if (order && order.products.length && String(order.vendorid) !== String(header.restaurantID)) {
      this.orderHeader = header;
      this.modalService.open(this.transferModal, { centered: true, windowClass: 'hideScroll' });
    } else {
      this.finishGoToMenu(header);
    }
  }

  private finishGoToMenu(header: OrderHeader) {
    this.selectedLocation = header.restaurantID;
    this.store
      .dispatch([
        new GetMenu(header.restaurantID.toString()),
        new GetRestaurantCapacity(String(header.restaurantID)),
        new EmptyPreviousBasket(),
      ])
      .subscribe(() => {
        if (header.orderType === 'pickup') {
          this.store.dispatch(new CreateBasket(header.restaurantID.toString())).subscribe(() => {
            const basketRes = this.store.selectSnapshot((state) => state);
            this.store.dispatch(new GetCalendars(basketRes.restaurant.restaurant.id, false)).subscribe(() => {
              this.store.dispatch(new GetTimeSlots(true, 'pickup')).subscribe(async () => {
                const slotRes = this.store.selectSnapshot((state) => state);
                if (slotRes.basket.timeSlots.length === 0) {
                  this.openSoldOutModal();
                } else {
                  const queryParams = await firstValueFrom(this.route.queryParams);
                  if (queryParams.return && queryParams.return === 'past-orders') {
                    this.router.navigate(['/account'], { fragment: 'recent' });
                  } else {
                    this.router.navigateByUrl('/menu');
                  }
                }
                this.selectedLocation = 0;
              });
            });
          });
        } else if (header.orderType === 'dispatch') {
          this.store.dispatch(new CreateBasket(header.restaurantID.toString())).subscribe(() => {
            const location = this.store.selectSnapshot<any>((state) => state.restaurant.location);
            const basketId = this.store.selectSnapshot<any>((state) => state.basket.basket.id);
            this.store.dispatch(new SetDispatchAddress(location, basketId)).subscribe(() => {
              this.store
                .dispatch([new SetHandoffMode('dispatch'), new GetCalendars(String(header.restaurantID), false)])
                .subscribe((res) => {
                  this.store.dispatch(new GetTimeSlots(true, 'dispatch')).subscribe(async () => {
                    const slotRes = this.store.selectSnapshot((state) => state);
                    if (slotRes.basket.timeSlots.length === 0) {
                      this.openSoldOutModal();
                    } else {
                      const queryParams = await firstValueFrom(this.route.queryParams);
                      if (queryParams.return && queryParams.return === 'past-orders') {
                        this.router.navigate(['/account'], { fragment: 'recent' });
                      } else {
                        this.router.navigateByUrl('/menu');
                      }
                    }
                    this.selectedLocation = 0;
                  });
                });
            });
          });
        } else if (header.orderType === 'dinein') {
          this.store.dispatch(new CreateBasket(header.restaurantID.toString())).subscribe(() => {
            this.store
              .dispatch([new SetHandoffMode('dinein'), new GetCalendars(String(header.restaurantID), false)])
              .subscribe(() => {
                this.store.dispatch(new GetTimeSlots(true, 'dinein')).subscribe(async () => {
                  const slotRes = this.store.selectSnapshot((state) => state);
                  if (slotRes.basket.timeSlots.length === 0) {
                    this.openSoldOutModal();
                  } else {
                    const queryParams = await firstValueFrom(this.route.queryParams);
                    if (queryParams.return && queryParams.return === 'past-orders') {
                      this.router.navigate(['/account'], { fragment: 'recent' });
                    } else {
                      this.router.navigateByUrl('/menu');
                    }
                  }
                  this.selectedLocation = 0;
                });
              });
          });
        }
      });
  }

  openSoldOutModal() {
    let modalRef;
    const modalOptions: NgbModalOptions = { centered: true, size: 'lg', windowClass: 'hideScroll' };
    modalRef = this.modalService.open(SoldoutModalComponent, modalOptions);
    modalRef.componentInstance.xClicked.subscribe((res: any) => {
      this.modalService.dismissAll();
    });
    modalRef.componentInstance.continueClicked.subscribe((res: any) => {
      this.router.navigateByUrl('/menu');
      this.modalService.dismissAll();
    });
  }

  doScheduledMessage() {
    this.cms.getScheduledMessages().subscribe((res) => {
      this.messages = res;
      const messageArray: ScheduledMessaging[] = [];
      this.messages.forEach((message) => {
        if (message.status === 'published') {
          if (Number(message.where) === 1 || Number(message.where) === 3) {
            // Shown on Locations or Both
            const today = moment();
            // console.log(today);
            // console.log(moment(message.start_date));
            switch (Number(message.schedule_type)) {
              case 1: // Recurring Message
                message.days_shown.forEach((day) => {
                  if (today.day() === Number(day)) {
                    messageArray.push(message);
                  }
                });
                break;
              case 2: // Single Day
                if (moment(today).isSame(moment(message.date_shown, 'YYYY-MM-DD'), 'day')) {
                  messageArray.push(message);
                }
                break;
              case 3: // Time Period
                if (
                  moment(today).isSameOrAfter(moment(message.start_date, 'YYYY-MM-DD')) &&
                  moment(today).isSameOrBefore(moment(message.end_date, 'YYYY-MM-DD'))
                ) {
                  messageArray.push(message);
                }
                break;
            }
            if (messageArray.length > 0) {
              this.activeMessage = messageArray[0];
              if (!this.modalService.hasOpenModals()) {
                this.modalService.open(this.messageModal, {
                  centered: true,
                  keyboard: false,
                  backdrop: 'static',
                });
              }
            }
          }
        }
      });
    });
  }

  transferConfirm() {
    this.modalService.dismissAll();
    this.finishGoToMenu(this.orderHeader);
  }
}
